import React from "react";
import {useField} from "../../../../hooks/use-form";
import NativeSelect, {SelectProps as NativeSelectProps} from "../../../Select";

interface SelectProps extends NativeSelectProps {
  form: string;
  name: string;
  iconMobile?: string;
  iconDesktop?: string;
}

function Select(props: SelectProps) {
  const {
    form,
    name,
    iconMobile,
    iconDesktop,
  } = props, {
    value,
    error,
    handleBlur,
    handleChange,
  } = useField(form, name);

  return (
    <NativeSelect
      {...props}
      value={value}
      iconMobile={iconMobile}
      iconDesktop={iconDesktop}
      error={error as string}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  )
}

export default Select;
