import React, { createElement, MouseEventHandler, ReactNode, useMemo } from "react";
import clxs from "./property-multi-config-item.module.css";
import CHEVRON_RIGHT_PRIMARY1 from "../../assets/icons/chevron-right-primary.svg";
import NetworkImg from "../NetworkImg";
import decodeLocationSlug from "../../../utils/decode-location-slug";
import useClx from "../../hooks/use-clx";
import CHEVRON_RIGHT_PRIMARY2 from "../../assets/lohono-black/icons/chevron-right-primary.svg";
import LoyaltyPointShortTicker from "../LoyaltyPointShortTicker";
// import ISPRAVA_LOGO from "../../assets/logo/isprava-built.png";
import LOHONO_BLACK_LOGO from "../../assets/lohono-black/logo/lohono-black.svg";
import PARTNER_HOME_ICON from "../../assets/partner-home-handshake.svg";
import { format } from "date-fns";
import useIsMobile from "../../hooks/use-is-mobile";
import { useSearchParams } from "react-router-dom";
import RatingCounts from "../PropertyDetail/RatingCounts";

interface PropertyMultiConfigItemProps {
  property: PropertyItem;
  routable?: boolean;
  className?: string;
  subscript?: string;
  children?: ReactNode;
  onClick?: MouseEventHandler;
}

function PropertyMultiConfigItem(props: PropertyMultiConfigItemProps) {
  const {
      property,
      routable = true,
      className,
      subscript = "/ night",
      children,
      onClick,
    } = props,
    {
      view_layout,
      location_slug,
      property_slug,
      thumbnail,
      accommodation,
      bath_count,
      pool_count,
      name,
      address,
      currency_symbol,
      sold_out,
      upcoming,
      status,
      brand,
      next_availability,
      configs,
      builder,
      bedroom_count,
      average_ratings,
      ratings_count,
    } = property,
    [search] = useSearchParams(),
    guest_count = useMemo(()=>{
      const guest = accommodation.split(" ");
      const count = parseInt(guest[guest.length - 1], 10); 
      return count;    
    },[accommodation]),
    specs = useMemo(
      () => {
        const specs: string[] = [
          `${accommodation} ${guest_count > 1 ? "Guests" : "Guset"}`,
        ];

        if (view_layout === "multi_config") {
          specs.push(`${bedroom_count} ${bedroom_count > 1 ? "Bedrooms" : "Bedroom"}`);
        } else if (configs.length) {
          const partial = configs.map(each => each.bedrooms).join(", ");

          specs.push(`${partial} Bedrooms`);
        }

        if (bath_count) {
          specs.push(`${bath_count} ${bath_count > 1 ? "Bathrooms" : "Bathroom"}`);
        }

        if (pool_count) {
          specs.push(`${pool_count} ${pool_count > 1 ? "Pools" : "Pool"}`);
        }

        return specs;
      },
      [
        view_layout,
        accommodation,
        bath_count,
        pool_count,
        bedroom_count,
        configs,
      ],
    ),
    { isMobile } = useIsMobile(),
    { location, destination } = useMemo(
      () => decodeLocationSlug(location_slug),
      [location_slug],
    ),
    isUnavailable = useMemo(
      () => status === "unavailable" || upcoming,
      [status, upcoming],
    ),
    secondary = useMemo(
      () => {
        const limit = isMobile ? 2 : 3;
        return configs.slice(0, limit);
      },
      [configs],
    ),
    isBlack = useMemo(
      () => brand === "lohono_black",
      [brand],
    ),
    ccx = useClx(
      clxs.container,
      isBlack ? clxs.black : null,
      className,
    ),
    rootElement = useMemo(
      () => {
        if (!routable) {
          return "div";
        }
        return "a";
      },
      [routable],
    ),
    nextAvailableDateNode = useMemo(
      () => {
        if (!sold_out) {
          return null;
        }

        const { checkin_date, checkout_date } = next_availability;

        if (!checkin_date || !checkout_date) {
          return null;
        }

        const checkin = format(new Date(checkin_date), "d LLL"),
          checkout = format(new Date(checkout_date), "d LLL");

        return (
          <div className={clxs.datesContainer}>
            Next Available Dates
            <div className={clxs.dates}>
              {`${checkin} - ${checkout}`}
            </div>
          </div>
        );

      },
      [
        next_availability,
        sold_out,
        clxs.datesContainer,
        clxs.dates,
      ],
    ),
    tickerNode = useMemo(
      () => {
        if (upcoming) {
          return (
            <div className={clxs.upcoming}>
              Coming soon
            </div>
          );
        }

        if (sold_out) {
          return (
            <div className={clxs.soldOut}>
              Sold out
            </div>
          );
        }

        if (isUnavailable) {
          return (
            <div className={clxs.unavailable}>
              Unavailable
            </div>
          );
        }

        return null;
      },
      [upcoming, sold_out, isUnavailable],
    ),
    builderBadge = useMemo(
      () => {
        if (isBlack) {
          return (
            <img
              src={LOHONO_BLACK_LOGO}
              alt="lohono-black"
              className={clxs.badge}
            />
          );
        }

        // if (builder === "isprava") {
        //   return (
        //     <img
        //       src={ISPRAVA_LOGO}
        //       alt="isprava"
        //       className={clxs.badge}
        //     />
        //   );
        // }

        if (builder === "partner") {
          return (
            <div className={clxs.partnerHome}>
              <img
                alt="partner home"
                className={clxs.partnerHomeIcon}
                src={PARTNER_HOME_ICON}
              />
              Partner Home
            </div>
          );
        }

        return null;
      },
      [builder, isBlack],
    ),
    cProps = useMemo(
      () => {
        const baseProps = {
          className: ccx,
          onClick: onClick,
        };

        if (!routable) {
          return baseProps;
        }

        const updatedSearchParams = new URLSearchParams(search);

        if (!updatedSearchParams.has("adult_count")) {
          updatedSearchParams.set("adult_count", "2");
        }

        if (
          sold_out
          && next_availability.checkin_date
          && next_availability.checkin_date
        ) {
          updatedSearchParams.set(
            "checkin_date",
            new Date(next_availability.checkin_date).toISOString(),
          );

          updatedSearchParams.set(
            "checkout_date",
            new Date(next_availability.checkout_date).toISOString(),
          );
        }

        const safeSearch = updatedSearchParams.toString(),
          path = `/villas/${destination}/${location}/${property_slug}`,
          url = safeSearch ? `${path}?${safeSearch}` : path;

        return {
          ...baseProps,
          href: url,
          title: `View details of ${name}`,
        };
      },
      [
        routable,
        name,
        location,
        destination,
        property_slug,
        next_availability,
        sold_out,
        ccx,
        onClick,
        search,
      ],
    );

  return createElement(
    rootElement,
    cProps,
    <>
      <NetworkImg
        src={thumbnail.url}
        alt={thumbnail.alt}
        desktopQuality={50}
        mobileQuality={75}
        className={clxs.thumbnail}
        mobileDimensions={[800, 600]}
        desktopDimensions={[800, 600]}
      />
      <h3
        className={clxs.title}
        title={name}
      >
        {name}
      </h3>
      {/* Check if both ratings_count and average_ratings */}
      {ratings_count && average_ratings ?
      <RatingCounts
        className={clxs.ratingCounts}
        average_ratings={average_ratings}
        ratings_count={ratings_count}
      /> : null }
      <div className={clxs.subtitle}>
        {address}
      </div>
      <div className={clxs.specContainer}>
        {specs.map(
          (each, i) => (
            <div
              key={i}
              className={clxs.spec}
            >
              {each}
            </div>
          ),
        )}
      </div>
      <div className={clxs.configurations}>
        {secondary.map((each, key) => {
          const {
              room_name,
              max_occupancy,
              bed_type,
              price,
              discounted_price,
              discounted_method,
              tier_discount_percentage,
            } = each,
            strikePrice = `${currency_symbol} ${price?.toLocaleString("en-IN", { maximumFractionDigits: 0 })}`,
            isShowDiscountedPrice = discounted_price && price && (discounted_price < price),
            finalPrice = `${currency_symbol} ${(discounted_price || price)?.toLocaleString("en-IN", { maximumFractionDigits: 0 })}`;

          return (
            <div
              className={clxs.configuration}
              key={key}
              title={room_name}
            >
              <div className={clxs.configTitle}>
                {room_name}
              </div>
              <div className={clxs.configSize}>
                {`Fits ${max_occupancy} (${bed_type})`}
              </div>
              {nextAvailableDateNode}
              {isUnavailable ? (<div>&nbsp;</div>) : (
                <div className={clxs.pricingContainer}>
                  <div className={clxs.startFrom}>
                    Start from
                    {(isShowDiscountedPrice && strikePrice) ? (
                      <span className={clxs.strike}>
                          {strikePrice}
                        </span>
                    ) : null}
                  </div>
                  <div className={clxs.price}>
                      <span className={clxs.amount}>
                        {finalPrice}
                      </span>
                    {subscript}
                    <sup>*</sup>
                  </div>
                  <div className={clxs.excTax}>
                    <LoyaltyPointShortTicker
                      discountPercentage={tier_discount_percentage}
                      method={discounted_method}
                      className={clxs.llpTicker}
                    />
                    (* Exclusive of taxes)
                  </div>
                </div>
              )}
              {builderBadge}
            </div>
          );
        })}
        <div className={clxs.more}>
          +
          <span className={clxs.moreOptions}>More Options</span>
        </div>
        <div className={clxs.ratings}>
        {tickerNode}
        </div>
        <div
          className={sold_out ? clxs.hideView :clxs.view}
          data-sold-out={sold_out}
        >
          <span>View details</span>
          <img
            src={isBlack ? CHEVRON_RIGHT_PRIMARY2 : CHEVRON_RIGHT_PRIMARY1}
            alt="jump"
          />
        </div>
      </div>
      {children}
    </>,
  );
}

export default PropertyMultiConfigItem;