import Fetch from "../../utils/fetch";

class StaticContentService implements IStaticContentService {
  private readonly fetch: Fetch<ApiResult>;

  constructor(fetch: Fetch<ApiResult>) {
    this.fetch = fetch;
  }

  async getFAQ (): Promise<FetchResult<FAQ[]>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getStaticData (): Promise<FetchResult<StaticData>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getPhoneExt (): Promise<FetchResult<PhoneExt[]>> {
    const url = new URL("/api/phone-ext", window.location.origin);

    return this.fetch.GET(url.toString());
  }

  async getPrivacyPolicy (): Promise<FetchResult<StaticContentSection[]>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getSitemap (): Promise<FetchResult<SitemapPartial[]>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getTnc (): Promise<FetchResult<StaticContentSection[]>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getLoyaltyPointsPolicy (): Promise<FetchResult<LoyaltyPointsPolicy>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }
}

export default StaticContentService;
