import React, { ReactNode } from "react";
import usePhoneExts from "../../../hooks/use-phone-exts";
import { useSendRequestMinimalDefaultValues } from "../../../hooks/use-send-request-default-values";
import Form, { FormProps } from "../../Form";
import clxs from "../send-request-form.module.css";
import useClx from "../../../hooks/use-clx";
import { Select, TextField } from "../../Form/Fields";
import { FormikValues } from "formik";
import MINIMAL_SCHEMA from "./minimal-schema";

interface MinimalProps extends Omit<FormProps, "validationSchema" | "defaultValue"> {
  titleNode?: ReactNode;
  defaultValue?: FormikValues;
  id: string;
  className?: string;
  children: ReactNode;
  isEventPage? : boolean;
}

function Minimal(props: MinimalProps) {
  const phoneExts = usePhoneExts(),
    { id, className,isEventPage } = props,
    ccx = useClx(clxs.form, className),
    fcx = useClx(clxs.twoColumn, "full-name"),
    pecx = useClx("phone-ext"),
    pcx = useClx("phone-no"),
    { titleNode, children, defaultValue, ...rest } = props,
    dv = useSendRequestMinimalDefaultValues(defaultValue);

  return (
    <Form
      {...rest}
      defaultValue={dv}
      className={ccx}
      validationSchema={MINIMAL_SCHEMA}
    >
      {titleNode}
      {isEventPage ?
      <TextField
        form={id}
        name="full_name"
        className={fcx}
        placeholder="Full name *"
      />
       : 
      <TextField
        form={id}
        name="full_name"
        className={fcx}
        label="Full name *"
      />}

      {isEventPage ?
      <Select
        form={id}
        name="phone_ext"
        options={phoneExts}
        className={pecx}
        placeholder="Phone code *"
      />
      :
      <Select
        form={id}
        name="phone_ext"
        options={phoneExts}
        className={pecx}
        label="Phone code *"
      />}

      {isEventPage ?
      <TextField
        form={id}
        name="phone_no"
        className={pcx}
        placeholder="Phone number *"
      />
      :
      <TextField
        form={id}
        name="phone_no"
        className={pcx}
        label="Phone number *"
      />}
      {children}
    </Form>
  );
}

export default Minimal;
