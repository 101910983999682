import { RefObject, useMemo } from "react";
import { useScroll } from "../../hooks/use-scroll";

function useCalendarDialogPosition<T extends HTMLElement> (
  position: "left" | "right",
  ref: RefObject<T>,
  open: boolean,
) {
  const { scroll } = useScroll();

  return useMemo(
    () => {
      if (typeof window === "undefined") {
        return { rect: null, styles: {} };
      }

      if (!ref.current) {
        return { rect: null, styles: {} };
      }

      const boundingClientRect = ref.current.getBoundingClientRect();

      const { left = 0, right = 0, bottom = 0 } = boundingClientRect;

      const isLeft = position === "left";

      const styles: any = {
        "--cd-top": `${bottom + 10}px`,
        "--cd-left": `${isLeft ? left : right}px`,
      };

      if (!isLeft) {
        styles["--cd-transform"] = "translateX(-100%)";
      }

      return {
        rect: boundingClientRect,
        styles: styles,
      };
    },
    [position, ref.current, open, scroll],
  );
}

export default useCalendarDialogPosition;
