import React from "react";
import { LoginModal } from "../../hooks/use-login-modal";
import { PhoneExtsManager } from "../../hooks/use-phone-exts";
import { SessionManager } from "../../hooks/use-session";
import { SnackbarManager } from "../../hooks/use-snackbar";
import AppStoreModal from "../AppStoreModal";
import DomainManager from "../DomainManager";
import StateProvider, {StateProviderProps} from "../StateProvider";
import Routes from "../Routes";
import GlobalStyles from "../GlobalStyles";
import {Page} from "../../../../typings/page";
import Header from "../Header";
import ScrollSpy from "../ScrollSpy";
import Footer from "../Footer";
import ContactUsFloating from "../ContactUsFloating";
import SendRequestModal from "../SendRequestForm/Modal";
import { IpGeoLocationManager } from "../../hooks/use-ip-geo-location";

interface AppProps extends Omit<StateProviderProps, "children"> {
  pages: Page[];
  children?: never;
}

function App (props: AppProps) {
  const { pages, ...stateProviderProps } = props;

  return (
    <StateProvider {...stateProviderProps}>
      <>
        <SessionManager/>
        <GlobalStyles/>
        <Header/>
        <PhoneExtsManager/>
        <Routes pages={pages}/>
        <ScrollSpy/>
        <Footer pages={pages}/>
        <ContactUsFloating/>
        <SendRequestModal/>
        <AppStoreModal/>
        <IpGeoLocationManager/>
        <DomainManager/>
        <LoginModal/>
        <SnackbarManager/>
      </>
    </StateProvider>
  );
}

export default App;
