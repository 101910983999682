import React, { useMemo } from "react";
import useClx from "../../../hooks/use-clx";
import usePathParams from "../../../hooks/use-path-params";
import clxs from "./outro.module.css";
import OUTRO_TEMPLATE from "../../../core/outro";

interface OutroProps {
  path: string;
  className?: string;
}

function Outro (props: OutroProps) {
  const { path, className } = props,
    destination = usePathParams(path, "destination"),
    location = usePathParams(path, "location"),
    location_slug = useMemo(
      () => `${destination}-${location}`,
      [destination, location],
    ),
    ccx = useClx(clxs.container, className),
    content = useMemo(
      () => OUTRO_TEMPLATE[location_slug] || null,
      [location_slug],
    );

  if (!content) {
    return null;
  }

  return (
    <div className={ccx}>
      <div className={clxs.overlay}>
        &nbsp;
      </div>
      <div className={clxs.content}>
        {content}
      </div>
    </div>
  );
}

export default Outro;
