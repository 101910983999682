import React, { useEffect,useRef,useState } from "react";
import clxs from "./locations.module.css";
import LocationHeader from "./LocationHeader";
import LocationExperience from "./LocationExperience";
import VillaItem from "../VillaItem";
import usePathParams from "../../hooks/use-path-params";
import decodeLocationSlug from "../../../utils/decode-location-slug";
import { Track } from "../AnalyticsManager";
import {useQueryParamValue } from "../../hooks/use-query-param";
import usePropertyListing from "../../hooks/use-property-listing";
import { useScroll } from "../../hooks/use-scroll";
import Modal from "../../hooks/use-modal";
import Gallery from "../PropertyDetail/Gallery";
import LandingpageForm from "../LandingPageForm";
import LocationFooter from "./LocationFooter";
import LocationLoader from "./LocationLoader";
import LocationContactUsFooter from "./LocationLoader/LocationContactUsFooter";
import NoVillasFound from "../CollectionListing/NoVillasFound";
import { LOCATIONS_BANNER_MAP } from "./location-banner-template";

function Locations() {
  const 
    location_slug = usePathParams(PATH, "location_slug") || "india-goa",
    destination= useQueryParamValue("string", "destination") || "default",
    checkin_date = useQueryParamValue("date", "checkin_date"),
    checkout_date = useQueryParamValue("date", "checkout_date"),
    adult_count = useQueryParamValue("integer", "adult_count"),
    child_count = useQueryParamValue("integer", "child_count"),
    search = useQueryParamValue("string", "search"),
    formRef = useRef<HTMLDivElement>(null),
    banner = LOCATIONS_BANNER_MAP[`${destination}`].image || LOCATIONS_BANNER_MAP["default"].image,
    title = LOCATIONS_BANNER_MAP[`${destination}`].title || LOCATIONS_BANNER_MAP["default"].title,
    subtitle =  LOCATIONS_BANNER_MAP[`${destination}`].subtitle || LOCATIONS_BANNER_MAP["default"].subtitle,
    footerSubtitle = LOCATIONS_BANNER_MAP[`${destination}`].footerSubtitle || LOCATIONS_BANNER_MAP["default"].footerSubtitle,
    { properties, getNextPage, isLoading, propertyCount } = usePropertyListing(
      location_slug,
      undefined,
      checkin_date?.toISOString(),
      checkout_date?.toISOString(),
      adult_count,
      child_count,
      search,
    ),
    { ratio } = useScroll(),
    [lengthId, setLengthId] = useState<number>(0),
    
    handleRatioChange = (ratio: number) => {
      if (ratio < 0.6) {
        return;
      }

      if (lengthId === properties.length) {
        return;
      }

      setLengthId(properties.length);
    };

  useEffect(() => handleRatioChange(ratio), [ratio]);

  useEffect(() => {
    if (!lengthId) {
      return;
    }

    getNextPage();
  }, [lengthId]);


  return (
  <div>
    <div className={clxs.container}>

      <LocationHeader
        banner={banner}
        className={clxs.bannerSection}
        bannerTitle={title}
        subtitle={subtitle}
      />
      <LocationExperience className={clxs.experienceSection} />

      <div className={clxs.properties}>
      <LocationLoader isLoading={isLoading}></LocationLoader>
        <div className={clxs.title}>Our Homes</div>
        {(!propertyCount && !isLoading) ? (
          <NoVillasFound />
        ) : null}
        {properties.map((each, i) => {
          const decodedLocation = decodeLocationSlug(each.location_slug),
            { location, destination } = decodedLocation,
            propertySlug = each.property_slug,
            href = `/villas/${destination}/${location}/${propertySlug}`,
            payload: any = {
              ...each,
              ...decodedLocation,
              href: href,
              index: i,
            };

          return (
            <Track 
              key={i} 
              event="villa_property_clicked" 
              payload={payload}
            >
              <VillaItem 
                className={clxs.villaItem} 
                property={each} 
              />
            </Track>
          );
        })}
      </div>

      <div className={clxs.sendRequestContainer}>
          <LandingpageForm
            className={clxs.sendRequest}
            title="Immediate Assistance" 
            formId="landing_page_form_top"
            ref={formRef}
          />
      </div>

      <div className={clxs.sendRequestContainer2}>
          <LandingpageForm
            className={clxs.sendRequest}
            title="Immediate Assistance" 
            formId="landing_page_form_bottom"
          />
      </div>
      <Modal id={GALLERY_MODAL_ID}>
          <Gallery />
      </Modal>
    </div>
     <LocationFooter
       className={clxs.locationFooter}
       footerSubtitle={footerSubtitle}
     />
     <LocationContactUsFooter
       formRef={formRef}
     />
  </div>
  );
}

export default Locations;

const GALLERY_MODAL_ID = "villa-gallery-mobile";

const PATH = "/locations/:location_slug";


