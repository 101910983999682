const EH_LOCATION_SLUGS = [
  "thailand-koh-samui",
  "thailand-phuket",
  "indonesia-bali",
  "srilanka-sri-lanka",
  "maldives-maldives",
];

const EH_LOCATION_SLUGS_SET = new Set(EH_LOCATION_SLUGS);

export {
  EH_LOCATION_SLUGS,
  EH_LOCATION_SLUGS_SET,
};
