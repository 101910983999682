import GENERIC_BANNER from "../../assets/listing-page-generic.jpg";
import ALIBAUG_BANNER from "../../assets/listing-page-alibaug.jpg";
import BALI_BANNER from "../../assets/listing-page-bali.jpg";
import COONOOR_BANNER from "../../assets/listing-page-coonoor.jpg";
import GOA_BANNER from "../../assets/listing-page-goa.jpg";
import KARJAT_BANNER from "../../assets/listing-page-karjat.jpg";
// import OOTY_BANNER from "../../../assets/listing-page-ooty.jpg";
import RISHIKESH_BANNER from "../../assets/listing-page-rishikesh.jpg";
import KOH_SAMUI_BANNER from "../../assets/listing-page-koh-samui.jpg";
import LONAVALA_BANNER from "../../assets/listing-page-lonavala.jpg";
import PHUKET_BANNER from "../../assets/listing-page-phuket.jpg";
import MAHABALESHWAR_BANNER from "../../assets/listing-page-mahabaleshwar.jpg";
import MUSSOORIE_BANNER from "../../assets/listing-page-mussoorie.jpg";
import BHIMTAL_BANNER from "../../assets/listing-page-bhimtal.jpg";
import SRINAGAR_BANNER from "../../assets/listing-page-srinagar.jpg";
import JIM_CORBETT_BANNER from "../../assets/listing-page-jim-corbett.jpg";
import JAIPUR_BANNER from "../../assets/listing-page-jaipur.jpg";
import SHIMLA_BANNER from "../../assets/listing-page-shimla.jpg";
import KASAULI_BANNER from "../../assets/listing-page-kasauli.jpg";
import SRI_LANKA_BANNER from "../../assets/listing-page-sri-lanka.jpg";
import MALDIVES_BANNER from "../../assets/listing-page-maldives.jpg";
import COORG_BANNER from "../../assets/listing-page-coorg.jpg";

export const LOCATIONS_BANNER_MAP : LocationBannerDetails = {
  "goa": {
    image: GOA_BANNER,
    title: "Finest Luxury Villas in Goa with Lohono Stays.",
    subtitle: "Experience Unmatched Comfort in the Heart of Goa.",
    footerSubtitle:
      "Your search for the finest luxury villas in Goa ends here. At Lohono Stays, we offer an exquisite selection of the best luxury villas in Goa, ensuring a lavish and unforgettable escape. Whether you're seeking private pool villas to stay in Goa, a holiday villa for relaxation, or a private villa for events and parties to celebrate special occasions, our accommodations provide unmatched comfort and elegance. Our pet-friendly villas ensure your furry friends can join in on the fun, making it a family-friendly destination. With villas boasting private pools and located in prime areas such as North Goa, Anjuna, Siolim, Parra, Baga, and Candolim, your Goan experience will be nothing short of extraordinary. Conveniently book your perfect villa in Goa with Lohono Stays and immerse yourself in the beauty and luxury of the coastal state.",
    titleTag:
      "Luxury Villas in Goa - Lohono Stays | Private Pool, Pet-Friendly Accommodations",
    metaDesc:
      "Discover the finest luxury villas in Goa with Lohono Stays. From private pool villas in prime areas like Anjuna, Siolim, and Baga to pet-friendly options, enjoy an unmatched Goan experience. Book your lavish escape today.",
  },
  "lonavala": {
    image: LONAVALA_BANNER,
    title: "Luxurious Villas in Lonavala and Khandala by Lohono Stays.",
    subtitle: "Unforgettable Getaways, Unmatched Comfort",
    footerSubtitle:
      "Discover the epitome of luxury with our curated selection of the best villas in Lonavala and Khandala, ideal for unforgettable getaways near Mumbai. Whether you're in search of the private villas in Khandala, the perfect private villa for a party in Lonavala, or a tranquil private pool villa near Mumbai, our accommodations offer unmatched comfort and elegance. Explore options that include villas facing the Pawna Lake or pet-friendly villas suitable for the whole family. With our selection of the best villas to stay in Lonavala and Khandala, your getaways near Mumbai will be an experience to remember.Choose Lohono Stays for a luxurious escape to the scenic beauty of Lonavala and Khandala, for your next short trip from Mumbai, and create cherished memories that will last a lifetime.",
    titleTag: "Best Luxury Villas in Lonavala and Khandala | Lohono Stays",
    metaDesc:
      "Discover luxury with the finest villas in Lonavala and Khandala. From private pool villas to pet-friendly accommodations, choose Lohono Stays for unforgettable getaways near Mumbai.",
  },
  "khandala": {
    image: LONAVALA_BANNER,
    title: "Luxurious Villas in Lonavala and Khandala by Lohono Stays.",
    subtitle: "Unforgettable Getaways, Unmatched Comfort",
    footerSubtitle:
      "Discover the epitome of luxury with our curated selection of the best villas in Lonavala and Khandala, ideal for unforgettable getaways near Mumbai. Whether you're in search of the private villas in Khandala, the perfect private villa for a party in Lonavala, or a tranquil private pool villa near Mumbai, our accommodations offer unmatched comfort and elegance. Explore options that include villas facing the Pawna Lake or pet-friendly villas suitable for the whole family. With our selection of the best villas to stay in Lonavala and Khandala, your getaways near Mumbai will be an experience to remember.Choose Lohono Stays for a luxurious escape to the scenic beauty of Lonavala and Khandala, for your next short trip from Mumbai, and create cherished memories that will last a lifetime.",
    titleTag: "Best Luxury Villas in Lonavala and Khandala | Lohono Stays",
    metaDesc:
      "Discover luxury with the finest villas in Lonavala and Khandala. From private pool villas to pet-friendly accommodations, choose Lohono Stays for unforgettable getaways near Mumbai.",
  },
  "alibaug": {
    image: ALIBAUG_BANNER,
    title: "Lohono Stays: Your Luxury Villa Experience in Alibaug Awaits.",
    subtitle:
      "Experience Exclusivity, Comfort, and Luxury with Lohono Stays in Alibaug.",
    footerSubtitle:
      "Looking for villas near Mumbai? Dive into luxury with our villas in Alibaug which are perfect for your family get-togethers, friend reunions or intimate gatherings for small or large groups. Explore the best luxury villas with private swimming pools, private lawns and premium concierge services. You can stay at one of our beachside villas or opt for pet-friendly villas, kid friendly villas and elderly friendly villas. Alibaug villas are accessible from the jetty (RORO or M2M ferries) or are a short drive away from Mumbai. With Lohono Stays you can be sure of exclusivity, safety, comfort, trust and curated personalized services. Experience the perfect Alibaug villa stays now! You can also explore our other luxury homestays across 20+ locations in India and SouthEast Asia.",
    titleTag:
      "Luxury Villas in Alibaug: Beachside & Pet-Friendly Options Near Mumbai | Lohono Stays",
    metaDesc:
      "Explore luxury villas in Alibaug with Lohono Stays. Beachside, pet-friendly getaways near Mumbai with premium amenities.",
  },
  "karjat": {
    image: KARJAT_BANNER,
    title:
      "Handpicked Luxury Villas in Karjat: Your Perfect Getaway Near Mumbai.",
    subtitle:
      "Elegance, Comfort, and Opulence: Experience Karjat Like Never Before with Lohono Stays.",
    footerSubtitle:
      "Your search for a luxury villa in Karjat ends here. Discover the epitome of opulence with our handpicked selection of the best luxury villas in Karjat, offering the perfect getaway near Mumbai. Whether you're searching for the best pool villas in Karjat, comfortable private villa for a party, or private holiday homes with river-access near Mumbai, our accommodations provide unmatched comfort and elegance. Explore options such as a holiday villa or pet-friendly villas, ideal for families. With a range of villas for family and kid-friendly villas in Karjat, your escape near Mumbai will be a memorable experience. Conveniently book your perfect villa online and enjoy the beauty of this stunning destination. Choose Lohono Stays for your luxury villa in Karjat, ensuring a lavish and memorable getaway near Mumbai.",
    titleTag:
      "Luxury Villas in Karjat - Premium Pool & Pet-Friendly Accommodations Near Mumbai | Lohono Stays",
    metaDesc:
      "Discover handpicked luxury villas in Karjat, perfect for getaways near Mumbai. Choose from pool villas, private party homes, river-access holiday homes, and family-friendly villas. Book online with Lohono Stays for an unforgettable experience.",
  },
  "coonoor": {
    image: COONOOR_BANNER,
    title: "Stunning homes that exude charm and luxury in the Nilgiris",
    subtitle: "",
    footerSubtitle: "",
    titleTag: "",
    metaDesc: "",
  },
  "coorg": {
    image: COORG_BANNER,
    title: "Luxury Villas in Coorg by Lohono",
    subtitle: "Experience Extraordinary Comfort & Style in Coorg.",
    footerSubtitle:
      "Experience a world of luxury with our thoughtfully selected range of premium villas in Coorg, including the best luxury villas for an unforgettable stay. Whether you're in search of the perfect holiday villa or an elegant private villa for your special events, our Coorg accommodations offer unparalleled comfort and style. Our villas in Coorg cater to your every need, whether you're seeking a peaceful getaway, a farm stay near Bangalore, or a private villa with a pool. With pet-friendly options and villas near Bangalore, your Coorg experience will be nothing short of extraordinary. Plan your family villa getaway in Coorg, and immerse yourself in the beauty and luxury of Lohono.",
    titleTag:
      "Premium Villas in Coorg - Experience Unmatched Luxury with Lohono",
    metaDesc:
      "Discover luxury at its best with Lohono's range of premium villas in Coorg. Whether you seek an unforgettable holiday, a serene farm stay, or a lavish private villa with a pool, find your perfect retreat here. Pet-friendly options available.",
  },
  "mahabaleshwar": {
    image: MAHABALESHWAR_BANNER,
    title: "Exquisite Luxury Villas in Mahabaleshwar",
    subtitle: "Immerse in Unmatched Comfort & Elegance with Lohono.",
    footerSubtitle:
      "Looking for the finest luxury villas in Mahabaleshwar for your next family trip from Mumbai? At Lohono Stays, we offer an exquisite selection of the best luxury villas in Mahabaleshwar, ensuring a lavish and unforgettable escape. Whether you're seeking the best villas to stay in Mahabaleshwar, a holiday villa for relaxation, or a private villa for a party in Mahabaleshwar, our accommodations provide unmatched comfort and elegance. Our pet-friendly villas ensure your furry friends can join in on the fun, making it a family-friendly destination. With villas boasting private pools, your Mahabaleshwar experience will be nothing short of extraordinary. Conveniently book your perfect villa in Mahabaleshwar, and immerse yourself in the beauty and luxury of Lohono, ideal for getaways near Mumbai.",
    titleTag:
      "Lohono Stays: Luxury Villas in Mahabaleshwar | Pet-Friendly Accommodations",
    metaDesc:
      "Discover the finest selection of luxury villas in Mahabaleshwar with Lohono Stays. Ideal for family trips, relaxation, or parties. Featuring private pools and pet-friendly options, our villas offer unmatched comfort, making it the perfect getaway near Mumbai.",
  },
  "mussoorie": {
    image: MUSSOORIE_BANNER,
    title:
      "Luxury Villas in Mussoorie and Dehradun: A Pinnacle of Comfort and Scenic Beauty",
    subtitle:
      "Your Perfect Hill Station Escape: Unique & Luxurious Mountain Stays.",
    footerSubtitle:
      "Discover the pinnacle of luxury with our handpicked selection of the best luxury villas in Mussoorie and Dehradun, making them the best getaways from Delhi. With private villas in Mussoorie or Dehradun, you can conveniently book your ideal stay– whether it's a luxury cottage, villa with a private pool and amenities, or a holiday villa facing the lush valleys. Our pet-friendly villas ensure your furry companions can join in on the fun as well! And if you're looking to celebrate, we offer private villas for party and event purposes in Mussoorie and Dehradun too. Our luxurious and unique mountain stays in Mussoorie and Dehradun provide the perfect escape for your family getaway. Choose from the best villas to rent and make the most of your time in these picturesque hill stations.",
    titleTag:
      "Best Luxury Villas in Mussoorie and Dehradun: Private Getaways from Delhi",
    metaDesc:
      "Discover top luxury villas in Mussoorie and Dehradun, perfect for getaways from Delhi. From cottages to pool villas with scenic views, our hand picked selections ensure a unique mountain stay. Pet-friendly options available!",
  },
  "dehradun": {
    image: MUSSOORIE_BANNER,
    title:
      "Luxury Villas in Mussoorie and Dehradun: A Pinnacle of Comfort and Scenic Beauty",
    subtitle:
      "Your Perfect Hill Station Escape: Unique & Luxurious Mountain Stays.",
    footerSubtitle:
      "Discover the pinnacle of luxury with our handpicked selection of the best luxury villas in Mussoorie and Dehradun, making them the best getaways from Delhi. With private villas in Mussoorie or Dehradun, you can conveniently book your ideal stay– whether it's a luxury cottage, villa with a private pool and amenities, or a holiday villa facing the lush valleys. Our pet-friendly villas ensure your furry companions can join in on the fun as well! And if you're looking to celebrate, we offer private villas for party and event purposes in Mussoorie and Dehradun too. Our luxurious and unique mountain stays in Mussoorie and Dehradun provide the perfect escape for your family getaway. Choose from the best villas to rent and make the most of your time in these picturesque hill stations.",
    titleTag:
      "Best Luxury Villas in Mussoorie and Dehradun: Private Getaways from Delhi",
    metaDesc:
      "Discover top luxury villas in Mussoorie and Dehradun, perfect for getaways from Delhi. From cottages to pool villas with scenic views, our hand picked selections ensure a unique mountain stay. Pet-friendly options available!",
  },
  "bhimtal": {
    image: BHIMTAL_BANNER,
    title: "Blissful luxury homes in a picturesque lake town.",
    subtitle: "",
    footerSubtitle: "",
    titleTag: "",
    metaDesc: "",
  },
  "srinagar": {
    image: SRINAGAR_BANNER,
    title:
      "Heritage and picturesque vistas coupled with the most beautiful homes.",
    subtitle: "",
    footerSubtitle: "",
    titleTag: "",
    metaDesc: "",
  },
  "pahalgam": {
    image: SRINAGAR_BANNER,
    title:
      "Heritage and picturesque vistas coupled with the most beautiful homes.",
    subtitle: "",
    footerSubtitle: "",
    titleTag: "",
    metaDesc: "",
  },
  "kasauli": {
    image: KASAULI_BANNER,
    title:
      "Heritage and picturesque vistas coupled with the most beautiful homes.",
    subtitle: "",
    footerSubtitle: "",
    titleTag: "",
    metaDesc: "",
  },
  // "india-ooty": OOTY_BANNER,
  "rishikesh": {
    image: RISHIKESH_BANNER,
    title: "Rejuvenating luxury abodes on the banks of the holy Ganges",
    subtitle: "",
    footerSubtitle: "",
    titleTag: "",
    metaDesc: "",
  },
  "jimcorbett": {
    image: JIM_CORBETT_BANNER,
    title: "Exclusive Private Pool Villas and Resorts in Jim Corbett.",
    subtitle: "Experience Luxury in the Heart of the Wilderness.",
    footerSubtitle:
      "Discover the epitome of luxury with our exclusive private pool villas and luxury resorts in Jim Corbett, offering an exceptional getaway experience. Whether you're planning a unique New Year's celebration with our new year villa rental options, searching for the best place to stay in Jim Corbett, or seeking a thrilling camp stay experience, our villas, resorts, and stays provide unmatched comfort and exclusivity. Explore diverse accommodation options, including jungle lodges, homestays, and accommodations in the jungle that provide access to Jim Corbett's captivating wildlife and adventurous activities. With pet-friendly resorts and a range of adventure activities in the park, you'll create lasting memories in the heart of Jim Corbett's natural wonders. Choose Lohono Stays for a luxurious escape, 5 hours for Delhi, in Jim Corbett's stunning wilderness.",
    titleTag:
      "Luxury Private Pool Villas & Resorts in Jim Corbett | Lohono Stays",
    metaDesc:
      "Discover luxury with our exclusive private pool villas, resorts, and unique stays in Jim Corbett. From thrilling jungle lodges to pet-friendly resorts, experience the heart of Jim Corbett's natural wonders, just 5 hours from Delhi.",
  },
  "jaipur": {
    image: JAIPUR_BANNER,
    title: "Experience Royal Luxury with Lohono Villas in Jaipur",
    subtitle: "Dive into Jaipur's Royal Comfort with Lohono Stays",
    footerSubtitle:
      "Looking for villas in Jaipur? Dive into luxury with our villas in Jaipur which are perfect for your family get-togethers, friend reunions or intimate gatherings for small or large groups. Explore the best luxury villas and traditional palace living with private swimming pools, private lawns and premium concierge services. You can stay at one of our Jaipur palace villas or opt for pet-friendly villas, kid friendly villas and elderly friendly villas. Our Jaipur villas are a short drive away from Delhi and are perfect for weekend getaways or even pre-wedding photoshoots. With Lohono Stays you can be sure of exclusivity, safety, comfort, trust and curated personalized services. Experience the perfect royal life through villa stays now! You can also explore our other luxury homestays across 20+ locations in India and South East Asia.",
    titleTag:
      "Luxury Villas in Jaipur | Lohono Stays - Royal Living & Exclusive Services",
    metaDesc:
      "Discover luxury villas in Jaipur with Lohono Stays. Enjoy palace living, private pools, and premium services. A short drive from Delhi, ideal for weekends and special occasions. Experience royal comfort.",
  },
  "shimla": {
    image: SHIMLA_BANNER,
    title: "Dive into the Luxury of Lohono's Shimla Villas.",
    subtitle: "Discover Shimla's Hidden Luxury with Lohono Stays.",
    footerSubtitle:
      "Looking for villas in Shimla? Dive into luxury with our villas in Shimla which are perfect for your family get-togethers, friend reunions or intimate gatherings for small or large groups. Explore the best villas with private lawns and premium concierge services. You can stay at one of our hilltop villas or opt for pet-friendly villas, kid friendly villas and elderly friendly villas. Our luxury homestays are a drive away from Delhi and are perfect for weekend getaways, yoga, meditation or wellness retreats. With Lohono Stays you can be sure of exclusivity, safety, comfort, trust and curated personalized services. Experience the perfect hidden Himalayan treasure through trusted and best rated luxury stays now! You can also explore our other luxury homestays across 20+ locations in India and South East Asia.",
    titleTag:
      "Luxury Villas in Shimla | Lohono Stays - Experience Himalayan Bliss",
    metaDesc:
      "Lohono's luxury villas in Shimla: hilltop stays, pet-friendly options, and weekend retreats. Experience Himalayan luxury a drive from Delhi.",
  },
  "kohsamui": {
    image: KOH_SAMUI_BANNER,
    title: "Beach Life At It’s Finest At Lohono’s Koh Samui",
    subtitle: "Perfectly crafted luxury villas and experiences for the discerning traveller",
    footerSubtitle: "Experience Thailand’s coastal living at its finest at Lohono. With soul-reviving villas in South-east Asia’s best beach destinations, immerse yourself in a retreat that whispers luxury with every tide. The salt in the air, the tantalising cuisines, the vibrant culture, each moment speaks a story. Our villa concierge orchestrates a spectrum of water experiences, from kayaking and fishing to surfing and sailing, ensuring an indulgent seaside stay. Let the waves be your melody as you discover the essence of coastal bliss with us at Lohono. With tropical, mountain stays, to villas by the beach in Koh Samui, there’s a stay for every mood at Lohono. Stay at villas with private pools blending into nature with 5 star amenities like Wi-Fi, housekeeping and villas in Koh Samui with complimentary breakfast, and other meal packages, such that you’ll be able to savour the flavours of the coast with our delectable local lip-smacking preparations in-house. Built by nature, coupled with our hospitality, there’s no place you’d rather be than at Lohono, offering the best stays in Koh Samui.",
    titleTag: "",
    metaDesc: "",
  },
  "phuket": {
    image: PHUKET_BANNER,
    title: "White-sand beaches, romantic sunsets and exquisite hospitality.",
    subtitle: "",
    footerSubtitle: "",
    titleTag: "",
    metaDesc: "",
  },
  "bali": {
    image: BALI_BANNER,
    title: "Bali At It’s Finest, With Lohono",
    subtitle:
      "Perfectly crafted luxury villas and experiences for the discerning traveller",
    footerSubtitle:
      "Experience Bali’s coastal living at its finest at Lohono. With soul-reviving villas in South-east Asia’s best beach destinations, immerse yourself in a retreat that whispers luxury with every tide. The salt in the air, the tantalising cuisines, the vibrant culture, each moment speaks a story. Our villa concierge orchestrates a spectrum of water experiences, from kayaking and fishing to surfing and sailing, ensuring an indulgent seaside stay. Let the waves be your melody as you discover the essence of coastal bliss with us at Lohono. With tropical, mountain stays in Ubud, to villas by the beach in Seminyak, there’s a stay for every mood at Lohono. Stay at villas with private pools blending into nature with 5 star amenities like Wi-Fi, housekeeping and villas in Bali with complimentary breakfast, and other meal packages, such that you’ll be able to savour the flavours of the coast with our delectable local lip-smacking preparations in-house. Built by nature, coupled with our hospitality, there’s no place you’d rather be than at Lohono, offering the best stays in Bali.",
    titleTag: "Bali At It’s Finest, With Lohono Stays",
    metaDesc:
      "Experience Bali’s coastal living at its finest at Lohono. With soul-reviving villas in South-east Asia’s best beach destinations, immerse yourself in a retreat that whispers luxury with every tide. The salt in the air, the tantalising cuisines, the vibrant culture, each moment speaks a story. Our villa concierge orchestrates a spectrum of water experiences, from kayaking and fishing to surfing and sailing, ensuring an indulgent seaside stay. Let the waves be your melody as you discover the essence of coastal bliss with us at Lohono. With tropical, mountain stays in Ubud, to villas by the beach in Seminyak, there’s a stay for every mood at Lohono.",
  },
  "srilanka": {
    image: SRI_LANKA_BANNER,
    title:"Tropical Living Meets Luxury, At Lohono’s Sri Lanka",
    subtitle: "Perfectly crafted luxury villas and experiences for the discerning traveller",
    footerSubtitle: "Experience Sri Lanka’s coastal charm at its most exquisite with Lohono. Nestled in the island's most sought-after beach destinations, our soul-soothing villas invite you to indulge in a retreat where luxury is woven into every moment. The gentle sea breeze, the aromatic spices, the rich cultural tapestry—each experience tells a tale of its own. Our villa concierge curates a range of oceanic adventures, from snorkelling and whale watching to deep-sea fishing and sailing, ensuring your stay is nothing short of extraordinary. Whether you seek tropical hillside retreats or beachfront villas in Kosgoda, Bentota, Lohono has a stay in Sri Lanka for every mood. Revel in villas with private pools in Sri Lanka seamlessly integrated into nature, complete with 5-star amenities like high-speed Wi-Fi, attentive housekeeping, and complimentary breakfast. Delight in the authentic coastal flavours crafted by our in-house chefs, delivering a culinary experience that captures the essence of Sri Lanka. Embrace nature’s beauty and our unmatched hospitality—there’s nowhere you’d rather be than at Lohono, offering the finest villas in Sri Lanka.",
    titleTag: "",
    metaDesc: "",
  },
  "maldives": {
    image: MALDIVES_BANNER,
    title: "Maldives At It’s Finest, With Lohono",
    subtitle: "Perfectly crafted luxury villas and experiences for the discerning traveller",
    footerSubtitle: "Experience Maldives’ coastal living at Lohono Stays. With soul-reviving villas in South-east Asia’s best and cleanest beach destinations, immerse yourself in a retreat that whispers luxury with every tide. The salt in the air, the tantalising cuisines, the vibrant culture, each moment speaks a story. Our villa concierge orchestrates a spectrum of water experiences, from kayaking and fishing to surfing and sailing, ensuring an indulgent seaside stay. Let the waves be your melody as you discover the essence of coastal bliss with us at Lohono. With tropical, mountain stays, to villas by the beach in Maldives, there’s a stay for every mood at Lohono. Stay at villas with private pools blending into nature with 5 star amenities like Wi-Fi, housekeeping and villas in Male with complimentary breakfast, and other meal packages, such that you’ll be able to savour the flavours of the coast with our delectable local lip-smacking preparations in-house. Built by nature, coupled with our hospitality, there’s no place you’d rather be than at Lohono, offering the best stays in Maldives.",
    titleTag: "",
    metaDesc: "",
  },
  "gurgaon": {
    image: PHUKET_BANNER,
    title: "Premium Villas in Gurgaon: Your Perfect Escape Near Delhi",
    subtitle: "Immerse in Unmatched Comfort & Style",
    footerSubtitle:
      "Discover a world of luxury with our carefully selected range of premium villas in Gurgaon, including the best luxury villas for your stay. Whether you're looking for the perfect holiday villa or an elegant private villa for your special events, our Gurgaon accommodations offer unmatched comfort and style. Our villas in Gurgaon cater to your every need, whether you're seeking a peaceful getaway or an exciting celebration. Choose from an array of pet-friendly options and villas with private pools for a memorable stay in Gurgaon, just a stone's throw away from Delhi. Whether you're planning a farm stay near Delhi or a peaceful getaway, our villas in Gurgaon offer the perfect escape. Conveniently book your perfect villa in Gurgaon, and immerse yourself in the beauty and luxury of Lohono, ideal for getaways near Delhi.",
    titleTag: "Luxury Villas in Gurgaon: Premium Accommodations Near Delhi",
    metaDesc:
      "Discover a world of luxury with our handpicked range of premium villas in Gurgaon. Perfect for holidays, special events, or a peaceful getaway. Pet-friendly options and private pools available. A stone's throw from Delhi.",
  },
  "default": {
    image: GENERIC_BANNER,
    title: "Finest Luxury Villas with Lohono Stays.",
    subtitle: "Unforgettable Getaways, Unmatched Comfort",
    footerSubtitle: "Lohono Stays specializes in creating and curating luxury holiday homes for rent all over the world. Tied in with our signature hospitality, the endeavour is to offer the most luxurious and comfortable holiday experiences to our guests with our private villas.",
    titleTag: "",
    metaDesc: "",
  },
};
