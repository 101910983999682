
import DESKTOP_BANNER1 from "../../assets/Event-Carousels-Wellness.jpg";
import DESKTOP_BANNER2 from "../../assets/Event-Carousels-Wedding.jpg";
import DESKTOP_BANNER3 from "../../assets/Event-Carousels-FnB.jpg";
import DESKTOP_BANNER4 from "../../assets/Event-Carousels-Adventure.jpg";
import MOBILE_BANNER4 from "../../assets/Carousels-Adventure-Mobile.jpg";
import MOBILE_BANNER3 from "../../assets/Carousels-FNB-Mobile.jpg";
import MOBILE_BANNER2 from "../../assets/Carousels-Wedding-Mobile.jpg";
import MOBILE_BANNER1 from "../../assets/Carousels-Wellness-Mobile.jpg";



const DEFAULT_BANNERS = [
  {
    desktop_image_url: DESKTOP_BANNER4,
    mobile_image_url: MOBILE_BANNER4,
    title: "Adventure On Your Mind? From Paramotoring, Guided Treks, Go-karting, To Private Wildlife Safaris",
  },
  {
    desktop_image_url: DESKTOP_BANNER3,
    mobile_image_url: MOBILE_BANNER3,
    title: "Crafting Culinary Journeys From Farm-to-Table, Barbecues & Gourmet Delights",
  },
  {
    desktop_image_url: DESKTOP_BANNER1,
    mobile_image_url:MOBILE_BANNER1 ,
    title: "Discover Serenity With Our Spa & Wellness Offerings",
  },
  {
    desktop_image_url:DESKTOP_BANNER2 ,
    mobile_image_url: MOBILE_BANNER2,
    title:"Begin Your Next Chapter With Harmony By Lohono Stays",
  },
];

export { DEFAULT_BANNERS };
