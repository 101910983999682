import React from "react";
import clxs from "./stars.module.css";
import EMPTY_STAR_ICON from "../../../../assets/icons/empty-star-icon.svg";
import RATING_STAR_ICON from "../../../../assets/icons/rating-star-icon.svg";
import HALF_RATING_STAR from "../../../../assets/icons/half-rating-star.svg";
import useClx from "../../../../hooks/use-clx";

interface StarsProps {
  className?: string;
  average_ratings: number;
  iconClassName?:string;
}

function Stars(props: StarsProps) {
  const { average_ratings, className ,iconClassName} = props;
  const ccx = useClx(clxs.container, className);
  const cci = useClx(clxs.icon , iconClassName);// Combine class names for the icons

  return (
    <div className={ccx}>
      {/* Create an array of 5 elements to represent the stars */}
      {Array.from({ length: 5 }, (elem, index) => {
        const i = index + 0.5; // Midpoint for half-star logic

        return (
          <span key={index}>
            {/* Determine which star icon to render based on average_ratings */}
            {average_ratings >= index + 1 ? (
              <img
                src={RATING_STAR_ICON} // Full star icon
                alt="icon"
                className={cci}
              />
            ) : average_ratings >= i ? (
              <img
                src={HALF_RATING_STAR} // Half star icon
                alt="icon"
                className={cci}
              />
            ) : (
              <img
                src={EMPTY_STAR_ICON} // Empty star icon
                alt="icon"
                className={cci}
              />
            )}
          </span>
        );
      })}
    </div>
  );
}

export default Stars;
