import React, { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAnalytics from "../../hooks/use-analytics";
import useAccountIcon from "../../hooks/use-account-icon";
import useClx from "../../hooks/use-clx";
import useIsMobile from "../../hooks/use-is-mobile";
import { useLoginModal } from "../../hooks/use-login-modal";
import { useLogout } from "../../hooks/use-session";
import { useSnackbar } from "../../hooks/use-snackbar";
import useUserWithInitials from "../../hooks/use-user-with-initials";
import { Track } from "../AnalyticsManager";
import ContextMenu from "../ContextMenu";
import Anchor from "../Header/Anchor";
import PointTag from "../PointTag";
import clxs from "./login.module.css";
import ACCOUNT_PRIMARY_ICON from "../../assets/icons/person-primary.svg";
import { useQueryParamValue } from "../../hooks/use-query-param";
import getSessionFromContext from "../../../server/utils/get-session-from-context";

interface LoginProps {
  className?: string;
}

function Login (props: LoginProps) {
  const { track } = useAnalytics();

  const enqueueSnackbar = useSnackbar();

  const ACCOUNT_ICON = useAccountIcon();

  const showLoginFlowModal = useLoginModal();

  const handleLogin = useCallback(
    () => showLoginFlowModal({}),
    [showLoginFlowModal],
  );

  const session = getSessionFromContext();

  const referralCode = useQueryParamValue("string",REFERRAL_CODE_QUERY_KEY);
  
  const logout = useLogout();

  const handleLogout = useCallback(
    async () => {
      if (!confirm("Are you sure?")) {
        return;
      }

      const err = await logout();

      if (err) {
        track("logout_unsuccessful", { error: err.message });

        enqueueSnackbar(err.message, "error");

        return;
      }

      track("logout_successful");
      
      push({ pathname: "/" }, { replace: true });
    },
    [logout],
  );

  const { isMobile } = useIsMobile();

  const push = useNavigate();

  const { user, initials, firstName } = useUserWithInitials();

  const handleAvatarClicked = useCallback(
    () => {
      if (!user) {
        return;
      }

      push(isMobile ? "/profile" : "/profile/edit");
    },
    [push, user, isMobile],
  );

  const { className } = props;

  const {pathname} = useLocation();
  
  
  const setSessionStorage = () => {
    sessionStorage.setItem(LOGIN_MANDATORY_KEY, "1");
  };
  
  /**
   * This method shows login popup after 5 seconds only if the user is not logged in, 
   * is on home page and once per session.
   */
  const showLogin3Sec = () => {
    const isHomePage = pathname == "/";
    const isModalAlreadyShown = sessionStorage.getItem(LOGIN_MANDATORY_KEY) == "1";
    const sessionId = localStorage.getItem(SESSION_KEY);
    if(!user && !sessionId?.length && isHomePage && !isModalAlreadyShown) {
      setTimeout(()=>{
        setSessionStorage();
        track("login_desktop_clicked");
        showLoginFlowModal({});
      },5e3)
    }
  };

  useEffect (() => {
    if(referralCode && referralCode.length) {
      showLoginFlowModal({});
    } else {
      showLogin3Sec();
    } 
  },[referralCode,session]);

  const ccx = useClx(clxs.container, className, "headerLogin");

  return (
    <div className={ccx}>
      {user ? (
        <>
          {initials ? (
            <Track event="header_profile_clicked">
              <div
                className={clxs.userAvatar}
                onClick={handleAvatarClicked}
              >
                {initials}
              </div>
            </Track>
          ) : (
            <Track event="header_login_clicked">
              <img
                src={ACCOUNT_PRIMARY_ICON}
                alt="profile"
                className={clxs.userAvatar}
                onClick={handleAvatarClicked}
              />
            </Track>
          )}
          <ContextMenu
            title="Profile"
            className={clxs.userName}
            menuClassName={clxs.userMenu}
            menu={(
              <>
                <Track
                  event="header_profile_my_trips_clicked"
                  payload={{
                    type: "page",
                    title: "My Trips",
                    header: "My Trips",
                    href: "/profile/trips",
                  }}
                >
                  <Anchor
                    title="My Trips"
                    href="/profile/trips"
                    className={clxs.userOption}
                  />
                </Track>
                <Track
                  event="header_profile_edit_clicked"
                  payload={{
                    type: "page",
                    title: "My Profile",
                    header: "My Profile",
                    href: "/profile/edit",
                  }}
                >
                  <Anchor
                    title="My Profile"
                    href="/profile/edit"
                    className={clxs.userOption}
                  />
                </Track>
                <Track
                  event="header_profile_loyalty_points_clicked"
                  payload={{
                    type: "page",
                    title: "Rewards",
                    header: "Rewards",
                    href: "/loyalty-points",
                  }}
                >
                  <Anchor
                    title="Rewards"
                    href="/loyalty-points"
                    className={clxs.rewards}
                  >
                    <PointTag/>
                  </Anchor>
                </Track>
                <Track
                  event="header_logout_clicked"
                  payload={{
                    type: "action",
                    title: "Logout",
                    header: "Logout",
                    href: "/profile/edit",
                  }}
                >
                  <div
                    title="My Profile"
                    className={clxs.userOption}
                    onClick={handleLogout}
                  >
                    Logout
                  </div>
                </Track>
              </>
            )}
          >
            {firstName}
          </ContextMenu>
        </>
      ) : (
        <>
          <Track event="login_mobile_clicked">
            <img
              src={ACCOUNT_ICON}
              alt="account_icon"
              className={clxs.accountIcon}
              onClick={handleLogin}
            />
          </Track>
          <Track event="login_desktop_clicked">
            <button
              className={clxs.loginButton}
              type="button"
              onClick={handleLogin}
            >
              Login / Sign Up
            </button>
          </Track>
        </>
      )}
    </div>
  );
}

export default Login;

const REFERRAL_CODE_QUERY_KEY = "referral_code";

const LOGIN_MANDATORY_KEY = "login_mandatory_shown";
const SESSION_KEY = "SESSION";
