import differenceInDays from "date-fns/differenceInDays";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import prepareMeta from "../../../utils/prepare-meta";
import useAnalytics from "../../hooks/use-analytics";
import useCountryCode from "../../hooks/use-country-code";
import usePathParam from "../../hooks/use-path-params";
import usePhoneExts from "../../hooks/use-phone-exts";
import usePropertyDetail from "../../hooks/use-property-detail";
import { useQueryParamValue, useSetQueryParam } from "../../hooks/use-query-param";
import useReservationService from "../../hooks/use-reservation-service";
import useScrollToTop from "../../hooks/use-scroll-to-top";
import { useSession, useUser } from "../../hooks/use-session";
import { useSnackbar } from "../../hooks/use-snackbar";
import useUtm from "../../hooks/use-utm";
import Form from "../Form";
import { Checkbox, Select, TextArea, TextField } from "../Form/Fields";
import SecurePayment from "../SecurePayment";
import Breakdown from "./Breakdown";
import CancellationPolicy from "./CancellationPolicy";
import Header from "./Header";
import Summary from "./Summary";
import { usePropertyConfigValues } from "../../hooks/use-property-config";
import { usePriceCalculatorValue } from "../../hooks/use-price-calculator";
import { useStorageNumber } from "../../hooks/use-storage";
import { usePersistedCheckoutFormValue, CheckoutFormInputs } from "./usePersistedCheckoutFormValue";
import CHECKOUT_SCHEMA from "./checkout-schema";
import STAR_ICON from "../../assets/icons/star-icon.svg";
import clxs from "./checkout.module.css";
import useEligibilityPoints from "../../hooks/use-eligibility-points";
import InnerHtml from "./InnerHtml";
import { useLoginModal } from "../../hooks/use-login-modal";

function Checkout () {
  useScrollToTop();

  const [qp] = useSearchParams(),
    push = useNavigate(),
    enqueueSnackbar = useSnackbar(),
    { utm } = useUtm(),
    // { RAZORPAY_API_KEY } = useEnv(),
    { service: reservationService } = useReservationService(),
    phoneExts = usePhoneExts(),
    { track } = useAnalytics(),
    session = useSession(),
    showLoginFlowModal = useLoginModal(),
    formRef = useRef<HTMLDivElement>(null),
    { countryCode } = useCountryCode(),
    location = usePathParam(PATH, "location"),
    destination = usePathParam(PATH, "destination"),
    property_slug = usePathParam(PATH, "property"),
    checkin_date = useQueryParamValue("date", "checkin_date"),
    checkout_date = useQueryParamValue("date", "checkout_date"),
    adult_count = useQueryParamValue("integer", "adult_count"),
    child_count = useQueryParamValue("integer", "child_count"),
    pre_book_meals = useQueryParamValue("boolean","pre_book_meals"),
    coupon_code = useQueryParamValue("string", COUPON_CODE_QUERY_KEY),
    error_payment_message = useQueryParamValue("string", "error"),
    { value: cid } = useStorageNumber("localStorage", "customer_id"),
    { propertyDetail } = usePropertyDetail(property_slug, checkin_date, checkout_date),
    { configArgs, configMap } = usePropertyConfigValues(property_slug, propertyDetail?.configs ?? []),
    avail_loyalty_points = useQueryParamValue("boolean", AVAIL_LOYALTY_POINTS_QUERY_KEY),
    user = useUser(),
    setRefundableBooking = useSetQueryParam("boolean", REFUNDABLE_BOOKING_QUERY_KEY),
    avail_refundable_booking = useQueryParamValue("boolean", REFUNDABLE_BOOKING_QUERY_KEY),
    [protectHtmlString, setHtmlString] = useState<string>(""),
    protectQuoteId = useQueryParamValue("string", REFUNDABLE_BOOKING_QUOTEID_KEY),
    selected_configs = configArgs.filter(each => each.required_rooms>0),
    { eligibilityPoints } = session ? useEligibilityPoints(checkin_date, checkout_date,property_slug,selected_configs,session) : {eligibilityPoints : null},
    // using custom-made hook to set form values in localstorage and setting data in form.
    {
      handleSetCheckoutFormValues: handleSetCheckoutFormValues,
      inputFormValues: localInputFormValues,
    } = usePersistedCheckoutFormValue(DEFAULT_INPUT_NAMES),
    // showLoginModal = () => {!user && showLoginFlowModal({})},
    defaultValue = useMemo(
      () => {
        const phoneNo = user?.phone_no.replace("+91", "") ?? "";

        return {
          property_slug: property_slug,
          checkin_date: checkin_date,
          checkout_date: checkout_date,
          adult_count: adult_count,
          pre_book_meals:pre_book_meals,
          child_count: child_count,
          full_name: localInputFormValues.name || user?.full_name || "",
          email: user?.email || "",
          phone_ext: localInputFormValues.phone_ext || (countryCode !== "IN" ? "+44" : "+91"),
          phone_no: localInputFormValues.phone || phoneNo,
          message: localInputFormValues.message || "",
          config_args: configArgs,
          tnc: true,
        };
      },
      [user, countryCode],
    ),
    {
      viewLayout,
      thumbnail,
    } = useMemo(
      () => {
        return {
          viewLayout: propertyDetail?.view_layout || "standard",
          thumbnail: propertyDetail?.banner.url || "",
          propertyName: propertyDetail?.name || "",
        };
      },
      [propertyDetail],
    ),
    totalGuests = useMemo(
      () => adult_count + child_count,
      [adult_count, child_count],
    ),
    totalNights = useMemo(
      () => {
        if (!checkin_date || !checkout_date) {
          return 0;
        }

        const diff = differenceInDays(checkout_date, checkin_date);

        return diff;
      },
      [checkin_date, checkout_date],
    ),
    { tentativePrice } = usePriceCalculatorValue(PRICE_CALCULATOR_ID),
    earningContent = useMemo(
      () => {
        if (!tentativePrice) {
          return null;
        }

        const { breakdown: { loyalty_points_earning } } = tentativePrice;

        if (!loyalty_points_earning) {
          return null;
        }

        const formatted = loyalty_points_earning.toLocaleString("en-IN");

        return `You will earn ${formatted} points on this booking`;
      },
      [tentativePrice],
    ),
    isMultiConfig = viewLayout === "multi_config",
    isDisplayProtectWidget = !isMultiConfig,
    handleDateChange = async () => {
      const updateQp = new URLSearchParams(qp);

      updateQp.set("focus", "rate_calculator");

      const search = `?${updateQp.toString()}`;

      return push({
        pathname: `/villas/${location}/${destination}/${property_slug}`,
        search: search,
      });
    },
    makePaymentFunction = async (value: any) => {
      
      if (!value) {
        return;
      }

      //Method of hook to set the values in localstorage if changed.
      handleSetCheckoutFormValues(value);

      const {
          property_slug,
          checkin_date,
          checkout_date,
          adult_count,
          child_count,
          full_name,
          email,
          phone_ext,
          phone_no,
          message,
          config_args,
        } = value,
        phone = `${phone_ext} ${phone_no}`,
        meta = prepareMeta(utm),
        LOHONO_BRAND = 1,
        ratingsPayload = JSON.parse(localStorage.getItem("ratingsPayload") || ""),
        trackingPayload: any = {
          ...value,
          ...meta,
          config_args: config_args,
          checkin_date: checkin_date.toISOString(),
          checkout_date: checkout_date.toISOString(),
          tentativePrice: tentativePrice,
          isLoyaltyPointsUsed: avail_loyalty_points,
          loyaltyPointsValue: avail_loyalty_points ? tentativePrice?.breakdown?.loyalty_points_redeemed_value : 0,
          meal_plan_opt: pre_book_meals,
          ...ratingsPayload,
        };

        // Old Gtag Event
        track(
          "checkout_make_payment_clicked",
          trackingPayload,
        );
        
        //Send tier level in coupon code if no coupon code is used.
        const tierLevel = tentativePrice.breakdown.discount_method;
        let couponCodePayload = coupon_code;
        if(!coupon_code || coupon_code === "NONE") {
          couponCodePayload = tierLevel.toUpperCase();
        }

        const redirectUrl = window.location.href;

        const { error: e1, response: r1 } = await reservationService.addReservation(
          {
            property_slug: property_slug,
            checkin_date: checkin_date.toISOString(),
            checkout_date: checkout_date.toISOString(),
            adult_count: adult_count,
            child_count: child_count,
            coupon_code: couponCodePayload,
            avail_loyalty_points: avail_loyalty_points,
            property_configs: config_args,
            brand: LOHONO_BRAND,
            full_name: full_name,
            email: email,
            phone_no: phone,
            customer_note: message,
            meta: meta,
            isRefundableOpted: avail_refundable_booking,
            protectQuoteId: protectQuoteId,
            redirect_failure_url: redirectUrl,
            pre_book_meals: pre_book_meals,
          },
          session,
        );

      if (e1) {
        enqueueSnackbar(e1.message, "error");

        track(
          "checkout_error",
          {
            ...trackingPayload,
            error: e1.message,
          },
        );
        return;
      }

      if (!r1) {
        enqueueSnackbar("unknown error", "error");

        track(
          "checkout_error",
          {
            ...trackingPayload,
            error: "unknown error",
          },
        );
        return;
      }

      const { payment_link } = r1;

      localStorage.setItem("trackingPayload", JSON.stringify(trackingPayload));

      //Firebase event name - payment_initiated
      track(
        "checkout_payment_initiated",
        trackingPayload,
      );
      
      //Redirect to just pay payment page when make payment button is clicked.
      window.location.replace(payment_link); 

    },
    handleMakePayment = (value: any) => {
      //If the user is not loggedin show login popup then allow to move to checkout page.
      if(!user) {
        const modalProps = {
          className: "",
          onSubmit: makePaymentFunction,
        }

        return showLoginFlowModal(modalProps).then((success: any) => {
          if(success) {
            makePaymentFunction(value);
          }
        })
      } else { //If the user is already logged in.
        return makePaymentFunction(value);
      }
    },
    handlePaymentError = () => {
      // If there was any error message in payment. Show snack bar.
      if (error_payment_message.length) {
        enqueueSnackbar(error_payment_message, "error");
        //Firebase event name - payment_failed
        const ratingsPayload = JSON.parse(localStorage.getItem("ratingsPayload") || "");
        track(
          "checkout_payment_failed",
          {
            cid,
            error: error_payment_message,
            tentativePrice: tentativePrice,
            property_type: viewLayout,
            isLoyaltyPointsUsed: avail_loyalty_points,
            loyaltyPointsValue: avail_loyalty_points ? tentativePrice?.breakdown?.loyalty_points_redeemed_value : 0,
            totalNights: totalNights,
            meal_plan_opt: pre_book_meals,
            ...ratingsPayload,
          },
        );
      }
    },
    setRefundableWidgetHtml = (htmlString: string) => {
      setHtmlString(htmlString);
    };

  // If payment fails due to any reason showing a snack bar with error after it's redirected here.
  useEffect(
    () => {
      handlePaymentError();
      setRefundableBooking(true);
    },
    [],
  );

  if (!propertyDetail) {
    return (
      <div className={clxs.placeholder}>
        &nbsp;
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className={clxs.container}>
        <SecurePayment className={clxs.secureMobile} />
        {earningContent && (
          <div className={clxs.earningsMobile}>
            <img
              src={STAR_ICON}
              alt="star"
            />
            {earningContent}
          </div>
        )}
        <CancellationPolicy
          className={clxs.cancellation}
          policies={propertyDetail.cancellation_policy}
        />
        <div className={clxs.stickyContainer}>
          {earningContent && (
            <div className={clxs.earningsDesktop}>
              <img
                src={STAR_ICON}
                alt="star"
              />
              {earningContent}
            </div>
          )}
          <Breakdown
            viewLayout={viewLayout}
            formId={CHECKOUT_FORM_ID}
            couponCodeSearchKey={COUPON_CODE_QUERY_KEY}
            availLoyaltyPointsSearchKey={AVAIL_LOYALTY_POINTS_QUERY_KEY}
            calculatorId={PRICE_CALCULATOR_ID}
            propertySlug={property_slug}
            checkinDate={checkin_date}
            checkoutDate={checkout_date}
            adultCount={adult_count}
            childCount={child_count}
            couponCode={coupon_code}
            configs={configArgs}
            totalNights={totalNights}
            totalGuests={totalGuests}
            availLoyaltyPoints={avail_loyalty_points}
            className={clxs.breakdown}
            loyaltyPointsBalance={user?.loyalty_point_metadata.points_balance} 
            configMap={configMap}
            eligibilityPointsObj={eligibilityPoints}
            refundableSearchKey={REFUNDABLE_BOOKING_QUERY_KEY}
            quoteIdSearchKey={REFUNDABLE_BOOKING_QUOTEID_KEY}
            isOptedRefundableBooking={avail_refundable_booking}
            isDisplayProtectWidget={isDisplayProtectWidget}
            setRefundableWidgetHtml={setRefundableWidgetHtml}
            formRef={formRef}
            // onFormSubmitClick={showLoginModal}          
          >
            <Checkbox
              form={CHECKOUT_FORM_ID}
              name="tnc"
              className={clxs.tnc}
              color="primary"
              label={(
                <div className={clxs.tncContent}>
                  {"I have read and accept the "}
                  <a
                    href="/terms-and-conditions"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Terms & Conditions
                  </a>{" and "}
                  <a
                    href="/privacy-policy"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Privacy Policies
                  </a>
                </div>
              )}
            />
          </Breakdown>
          <SecurePayment className={clxs.secureDesktop} />
        </div>
        <Summary
          viewLayout={viewLayout}
          propertyName={propertyDetail.name}
          propertyAddress={propertyDetail.address}
          checkinDate={checkin_date}
          checkoutDate={checkout_date}
          adultCount={adult_count}
          preBookMeals={pre_book_meals}
          childCount={child_count}
          thumbnail={thumbnail}
          className={clxs.summary}
          onDateChange={handleDateChange} 
          configArgs={configArgs} 
          configMap={configMap}        
        />
        {(isDisplayProtectWidget) ? (
          <InnerHtml 
            classString={clxs.protectContainer} 
            htmlString={protectHtmlString}
          ></InnerHtml>
        ): ""}  
        <Form
          id={CHECKOUT_FORM_ID}
          className={clxs.details}
          defaultValue={defaultValue}
          validationSchema={CHECKOUT_SCHEMA}
          onSubmit={handleMakePayment}
          enableReinitialize={true}
        >
          <div 
            className={clxs.detailsTitle} 
            ref={formRef}
          >
            Enter your details
          </div>
          <TextField
            form={CHECKOUT_FORM_ID}
            name="full_name"
            label="Full name *"
            className={clxs.fullName}
          />
          <div className={clxs.phoneNoContainer}>
            <Select
              form={CHECKOUT_FORM_ID}
              name="phone_ext"
              options={phoneExts}
              label="Phone code *"
            />
            <TextField
              form={CHECKOUT_FORM_ID}
              name="phone_no"
              label="Phone number *"
            />
          </div>
          <div className={clxs.detailsTitle}>
            Do you have any special notes or requests?
          </div>
          <div className={clxs.detailsSubtitle}>
            Special requests cannot be guaranteed but the accommodation will do its best to meet your needs, you can
            always add these after booking is made too
          </div>
          <TextArea
            form={CHECKOUT_FORM_ID}
            name="message"
            label="Message"
            className={clxs.note}
            rows={4}
          />
        </Form>      
      </div>
    </>
  );
}

export default Checkout;

//Names of input field whose value is to be stored in local storage.
const DEFAULT_INPUT_NAMES: CheckoutFormInputs = {
  name: "full_name",
  phone_ext: "phone_ext",
  phone: "phone_no",
  message: "message",
};

const CHECKOUT_FORM_ID = "checkout";

const PRICE_CALCULATOR_ID = "checkout-rate-calculator";

const COUPON_CODE_QUERY_KEY = "coupon_code";

const AVAIL_LOYALTY_POINTS_QUERY_KEY = "avail_loyalty_points";

const REFUNDABLE_BOOKING_QUOTEID_KEY = "quote_id";

const REFUNDABLE_BOOKING_QUERY_KEY = "avail_refundable_booking";

const PATH = "/villas/:destination/:location/:property/checkout";
