import React, { useMemo } from "react";
import CHEVRON_LEFT_GREY_ICON from "../../assets/icons/chevron-left-grey.svg";
import usePropertyLocations from "../../hooks/use-property-locations";
import Button from "../Button";
import Form from "../Form";
import {
  AsyncDateRange,
  DateRange,
  PropertyConfig,
  Select,
  Occupancy,
} from "../Form/Fields";
import clxs from "./property-search-mobile.module.css";

interface PropertySearchMobileProps {
  viewLayout: string;
  formId: string;
  onSubmit?: (values: any) => void;
  packageOptions?: any; 
  defaultValue?: {
    destination?: string;
    stay?: {
      start: Date | null,
      end: Date | null,
    };
    occupancy?: { adult_count: number; child_count: number; };
    price_package?: string;
  };
  configs: PropertyConfig[];
  disabled?: ("destination" | "stay" | "occupancy" | "price_package")[];
  hidden?: ("destination" | "stay" | "occupancy" | "price_package")[];
  maxAdultCount?: number;
  maxChildCount?: number;
  validationSchema?: any | (() => any),
  propertySlug?: string;
  configMaxCount?: Record<string, number>;
}

function PropertySearchMobile (props: PropertySearchMobileProps) {
  const { propertyLocations } = usePropertyLocations(),
    {
      viewLayout,
      formId,
      defaultValue: _defaultValue = {},
      disabled: _disabled = [],
      hidden: _hidden = [],
      onSubmit,
      maxAdultCount = 12,
      maxChildCount = 8,
      validationSchema,
      // configs,
      propertySlug = "",
      // configMaxCount,
      packageOptions,
    } = props,
    locationOptions = useMemo(
      () => propertyLocations.reduce(
        (compiled, each) => {
          const { name, slug, coming_soon } = each;

          if (coming_soon) {
            return compiled;
          }

          const option = {
            label: name,
            value: slug,
          };

          return [
            ...compiled,
            option,
          ];
        },
        [] as { label: string; value: string }[],
      ),
      [propertyLocations],
    ),
    disabled = useMemo(
      () => new Set(_disabled),
      [_disabled],
    ),
    hidden = useMemo(
      () => new Set(_hidden),
      [_hidden],
    ),
    defaultValue = useMemo(
      () => ({
        ...DEFAULT_VALUE,
        ..._defaultValue,
      }),
      [_defaultValue],
    );

  return (
    <Form
      id={formId}
      defaultValue={defaultValue}
      className={clxs.container}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <div className={clxs.header}>
        <img
          src={CHEVRON_LEFT_GREY_ICON}
          alt="back"
          onClick={onSubmit?.bind(null, null)}
          className={clxs.back}
        />
        {!hidden.has("destination") && (
          <Select
            form={formId}
            name="destination"
            options={locationOptions}
            searchable={false}
            disabled={disabled.has("destination")}
          />
        )}
      </div>
      {!hidden.has("stay") && (
        propertySlug ? (
          <AsyncDateRange
            form={formId}
            name="stay"
            labels={["Check-In", "Check-Out"]}
            disabled={disabled.has("stay")}
            propertySlug={propertySlug}
          />
        ) : (
          <DateRange
            form={formId}
            name="stay"
            labels={["Check-In", "Check-Out"]}
            disabled={disabled.has("stay")}
          />
        )
      )}
      {!hidden.has("occupancy") && (
        <div>
          <div className={clxs.addGuest}>
            Add guests *
          </div>
          <Occupancy
            form={formId}
            name="occupancy"
            maxAdultCount={maxAdultCount}
            maxChildCount={maxChildCount}
            disabled={disabled.has("occupancy")}
          />
        </div>
      )}
      {/*{viewLayout === "multi_config" ? (
        <div>
          <div className={clxs.addGuest}>
            Add rooms *
          </div>
          <PropertyConfig
            form={formId}
            name="config_args"
            configs={configs}
            propertySlug={propertySlug}
            maxCount={configMaxCount}
          />
        </div>
      ) : <div>&nbsp;</div>}*/}
      {viewLayout === "package" ? (
        <Select
          form={formId}
          label="Package *"
          name="package_slug"
          options={packageOptions}
          searchable={false}
        />
        ) : null}
      <Button
        className={clxs.done}
        type="submit"
      >
        Done
      </Button>
    </Form>
  );
}

export default PropertySearchMobile;

const DEFAULT_VALUE = {
  destination: "india-goa",
  stay: { start: null, end: null },
  occupancy: { adult_count: 2, child_count: 0 },
  config_args: {},
};
