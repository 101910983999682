import React, { useMemo } from "react";
import useClx from "../../../hooks/use-clx";
import usePropertyLocations from "../../../hooks/use-property-locations";
import { useStorageDate, useStorageNumber, useStorageString } from "../../../hooks/use-storage";
import Button from "../../Button";
import Form from "../../Form";
import { DateRange, Select, OccupancySelect } from "../../Form/Fields";
import PROPERTY_SEARCH_SCHEMA from "./property-search-schema";
import clxs from "./property-search.module.css";

interface PropertySearchProps {
  className?: string;
  onPropertySearch: (values: any) => void;
}

function PropertySearch(props: PropertySearchProps) {
  const { propertyLocations } = usePropertyLocations(),
    locationOptions = useMemo(
      () => propertyLocations.map(
        each => {
          const { name, slug } = each,
            option = {
              label: name,
              _value: slug,
              value: slug,
            };

          return option;
        },
      ),
      [propertyLocations],
    ), {
      value: destination,
      setValue: setDestination,
    } = useStorageString("sessionStorage", "destination"), {
      value: checkinDate,
      setValue: setCheckinDate,
    } = useStorageDate("sessionStorage", "checkin_date"), {
      value: checkoutDate,
      setValue: setCheckoutDate,
    } = useStorageDate("sessionStorage", "checkout_date"), {
      value: adultCount,
      setValue: setAdultCount,
    } = useStorageNumber("sessionStorage", "adult_count"), {
      value: childCount,
      setValue: setChildCount,
    } = useStorageNumber("sessionStorage", "child_count"),
    defaultValues = useMemo(
      () => {
        const defaultValue = {
          destination: destination || DEFAULT_VALUES.destination,
          stay: {
            start: checkinDate || DEFAULT_VALUES.stay[0],
            end: checkoutDate || DEFAULT_VALUES.stay[1],
          },
          occupancy: {
            adult_count: adultCount || DEFAULT_VALUES.occupancy.adult_count,
            child_count: childCount || DEFAULT_VALUES.occupancy.child_count,
          },
        };

        return defaultValue;
      },
      [],
    ),
    { className, onPropertySearch } = props,
    formId = "property-search",
    ccx = useClx(clxs.container, className),
    handleSubmit = (values: any) => {
      const {
        destination,
        stay: {
          start: checkin_date,
          end: checkout_date,
        },
        occupancy: {
          adult_count,
          child_count,
        },
      } = values;

      setDestination(destination);
      setCheckinDate(checkin_date);
      setCheckoutDate(checkout_date);
      setAdultCount(adult_count);
      setChildCount(child_count);

      return onPropertySearch(values);
    };

  return (
    <Form
      id={formId}
      defaultValue={defaultValues}
      className={ccx}
      validationSchema={PROPERTY_SEARCH_SCHEMA}
      onSubmit={handleSubmit}
    >
      <div className={clxs.title}>
        Handpicked homes, <br/>
        paired with unparalleled hospitality
      </div>
      <Select
        form={formId}
        name="destination"
        label="Destination *"
        options={locationOptions}
        searchable={true}
        className={clxs.destination}
      />
      <DateRange
        form={formId}
        name="stay"
        labels={["Check-In", "Check-Out"]}
        className={clxs.stay}
        calendarDialogProps={{ monthCount: 2 }}
      />
      <OccupancySelect
        form={formId}
        name="occupancy"
        label="Guests"
        className={clxs.destination}
        maxAdultCount={24}
        maxChildCount={8}
      />
      <div className={clxs.searchContainer}>
        <Button
          type="submit"
          className={clxs.search}
        >
          Search
        </Button>
      </div>
    </Form>
  );
}

export default PropertySearch;

const DEFAULT_VALUES = {
  destination: "india-goa",
  stay: { start: null, end: null },
  occupancy: { adult_count: 2, child_count: 0 },
};
