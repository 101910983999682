import React, { ReactNode, useMemo } from "react";
import useClx from "../../hooks/use-clx";
import { useField } from "../../hooks/use-form";
import usePhoneExts from "../../hooks/use-phone-exts";
import usePropertyLocations from "../../hooks/use-property-locations";
import Form, { FormProps } from "../Form";
import { Select, TextArea, TextField } from "../Form/Fields";
import clxs from "./send-request-form.module.css";
import INTEREST_OPTIONS from "../../core/interest";
import NO_OF_GUESTS_OPTIONS from "../../core/guests";
import { SEND_REQUEST_SCHEMA, SEND_CORPORATE_REQUEST_SCHEMA } from "./send-request-schema";
import { useSendRequestDefaultValues } from "../../hooks/use-send-request-default-values";
import { useLocation } from "react-router-dom";

interface SendRequestFormProps extends Omit<FormProps, "validationSchema" | "defaultValue"> {
  titleNode?: ReactNode;
  defaultValue?: any;
  id: string;
  className?: string;
  children: ReactNode;
  isClearOnSubmit?:boolean;
  isOccasionForm?:boolean;
}

function SendRequestForm (props: SendRequestFormProps) {
  const { propertyLocations } = usePropertyLocations(),
    phoneExts = usePhoneExts(),
    { pathname } = useLocation(),
    locationOptions = useMemo(
      () => propertyLocations.map(
        each => {
          const { name, slug } = each,
            option = {
              label: name,
              _value: slug,
              value: slug,
            };

          return option;
        },
      ),
      [propertyLocations],
    ),
    { id, className, isClearOnSubmit, isOccasionForm} = props,
    { value } = useField(id, "interest"),
    isRental = value === "rental",
    isEventsPage = pathname?.includes("experiences-and-events"),
    ccx = useClx(clxs.form, className),
    fcx = useClx(clxs.twoColumn, "full-name"),
    pecx = useClx("phone-ext"),
    pcx = useClx("phone-no"),
    ecx = useClx(clxs.twoColumn, "email"),
    icx = useClx(!isRental && clxs.twoColumn, "interest"),
    lcx = useClx("location"),
    gcx = useClx(clxs.twoColumn, "guests"),
    mcx = useClx(clxs.msgColumn, "message"),
    bcx = useClx(clxs.twoColumn, "guests"),
    { titleNode, children, defaultValue, ...rest } = props,
    isShowGuestField = props?.defaultValue?.isShowGuestField,
    dv = useSendRequestDefaultValues(defaultValue);

  return (
    <Form
      {...rest}
      className={ccx}
      defaultValue={dv}
      validationSchema={isShowGuestField ? SEND_CORPORATE_REQUEST_SCHEMA : SEND_REQUEST_SCHEMA}
      isClearOnSubmit={isClearOnSubmit}
    >
      {titleNode}

      {isOccasionForm ?
      <TextField
        form={id}
        name="full_name"
        className={fcx}
        placeholder="Full name *"
      />
      :
      <TextField
        form={id}
        name="full_name"
        className={fcx}
        label="Full name *"
      />}

      {isOccasionForm ?
      <Select
        form={id}
        name="phone_ext"
        options={phoneExts}
        className={pecx}
        placeholder="Phone code *"
      />
       :
      <Select
        form={id}
        name="phone_ext"
        options={phoneExts}
        className={pecx}
        label="Phone code *"
      />}

      {isOccasionForm ?
      <TextField
        form={id}
        name="phone_no"
        className={pcx}
        placeholder="Phone number *"
      />
      :
      <TextField
        form={id}
        name="phone_no"
        className={pcx}
        label="Phone number *"
      />}

      {isOccasionForm ?
      <TextField
        form={id}
        name="email"
        className={ecx}
        placeholder="Email *"
      />
      :
      <TextField
        form={id}
        name="email"
        className={ecx}
        label="Email *"
      />}

      {isOccasionForm || isEventsPage! ? null : (
      <Select
        form={id}
        name="interest"
        options={INTEREST_OPTIONS}
        className={icx}
        label="Interested in *"
      /> )}

      {isRental && (
        <Select
          form={id}
          name="location"
          options={locationOptions}
          className={lcx}
          label="Location *"
        />
      )}

      {isOccasionForm ? (
        <Select
          form={id}
          name="location"
          options={locationOptions}
          className={bcx}
          placeholder="Location *"
        />
      ) : isEventsPage ? (
        <Select
          form={id}
          name="location"
          options={locationOptions}
          className={bcx}
          label="Location *"
        />
      ) : null}

      {isShowGuestField && (
        <Select
          form={id}
          name="guests"
          options={NO_OF_GUESTS_OPTIONS}
          className={gcx}
          label="No. of Guests *"
        />
      )}

      {isOccasionForm ? null :
      <TextArea
        form={id}
        name="message"
        rows={3}
        className={mcx}
        label="Message"
      />}
      
      {/* {isOccasionForm || isEventsPage ? null :
      <TextArea
        form={id}
        name="message"
        rows={3}
        className={mcx}
        label="Message"
      />} */}
      {children} 
    </Form>
  );
}

export default SendRequestForm;
