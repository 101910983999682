import { useMemo } from "react";

function useBreakdownTemplate(
  currencySymbol: string = "",
  breakdown: PriceBreakdown = DEFAULT_BREAKDOWN,
) {
  const { total: t } = breakdown,
    total = useMemo(
      () => `${currencySymbol} ${t.toLocaleString("en-IN")}`,
      [currencySymbol, t],
    ),
    template = useMemo(
      () => {
        const { gross_base_rate, deposit, taxes, discount } = breakdown,
          template = [
            {
              label: "Charges",
              value: `${currencySymbol} ${gross_base_rate.toLocaleString("en-IN")}`,
            },
            {
              label: "Security deposit (Refundable)",
              value: `${currencySymbol} ${deposit.toLocaleString("en-IN")}`,
            },
          ];

        if (discount) {
          template.push({
            label: "Discount",
            value: `-${currencySymbol} ${discount.toLocaleString("en-IN")}`,
          });
        }

        template.push(
          {
            label: "Applicable taxes",
            value: `${currencySymbol} ${taxes.toLocaleString("en-IN")}`,
          },
        );

        return template;
      },
      [currencySymbol, breakdown],
    );

  return {
    total: total,
    template: template,
  };
}

export default useBreakdownTemplate;

const DEFAULT_BREAKDOWN: PriceBreakdown = {
  gross_base_rate: 0,
  taxes: 0,
  convenience_fee: 0,
  deposit: 0,
  discount: 0,
  discount_message: "",
  extra_adults: 0,
  extra_adults_fare: 0,
  total: 0,
  rental_charges: 0,
  coupon_code: "",
  discount_type: "",
  discount_value: 0,
  loyalty_points_discount_percentage: 0,
  loyalty_points_discount_percentage_value: 0,
  loyalty_points_earning: 0,
  loyalty_points_redeemed_value: 0,
  discount_method: "",
  premium: 0,
};
