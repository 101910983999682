import { useSnackbar } from "../use-snackbar";
import useReservationService from "../use-reservation-service";
import useAnalytics from "../use-analytics";
import { useSession } from "../use-session";
import { useAtomState } from "../use-atom";
import { useCallback, useEffect } from "react";

function usePriceCalculator(
  id: string,
  property_slug: string,
  checkin_date: Date | null,
  checkout_date: Date | null,
  adult_count: number,
  child_count: number,
  coupon_code: string,
  property_configs: PropertyConfigArg[],
  avail_loyalty_points?: boolean,
  display_protect_widget?: boolean,
  is_refundable_opted?: boolean,
  protect_quote_id?: string,
) {
  const enqueueSnackbar = useSnackbar(),
    { service } = useReservationService(),
    { track } = useAnalytics(),
    session = useSession(),
    [tentativePrice, setTentativePrice] = useAtomState(id, DEFAULT_TENTATIVE_PRICE),
    handleGetTentativePrice = useCallback(
      (
        property_slug: string,
        checkin_date: Date | null,
        checkout_date: Date | null,
        adult_count: number,
        child_count: number,
        coupon_code: string,
        property_configs: PropertyConfigArg[],
        avail_loyalty_points?: boolean,
        display_protect_widget?: boolean,
        is_refundable_opted?: boolean,
        protect_quote_id?: string,
      ) => {
        let cancelled = false;

        (async () => {
          const trackingPayload: any = {
            property_slug: property_slug,
            checkin_date: checkin_date,
            checkout_date: checkout_date,
            adult_count: adult_count,
            child_count: child_count,
            coupon_code: coupon_code,
            avail_loyalty_points: avail_loyalty_points,
            display_protect_widget: display_protect_widget,
            is_refundable_opted: is_refundable_opted,
            protect_quote_id: protect_quote_id,
          };

          if (
            (checkin_date && !checkout_date)
            || (!checkin_date && checkout_date)
          ) {
            track(
              "rate_calculator_config_change",
              {
                ...trackingPayload,
                error: "incomplete date selection",
              },
            );

            return;
          }

          const { error, response } = await service.getTentativePrice(
            {
              property_slug: property_slug,
              checkin_date: checkin_date?.toISOString(),
              checkout_date: checkout_date?.toISOString(),
              adult_count: adult_count,
              child_count: child_count,
              coupon_code: coupon_code,
              avail_loyalty_points: avail_loyalty_points,
              property_configs: property_configs,
              display_protect_widget: display_protect_widget,
              is_refundable_opted: is_refundable_opted,
              protect_quote_id: protect_quote_id,
            },
            session,
          );

          if (cancelled) {
            return;
          }

          if (error) {
            enqueueSnackbar(error.message, "error");

            track(
              "rate_calculator_config_change",
              {
                ...trackingPayload,
                error: error.message,
              },
            );

            
            setTentativePrice(DEFAULT_TENTATIVE_PRICE);

            return;
          }

          if (!response) {
            setTentativePrice(DEFAULT_TENTATIVE_PRICE);

            track(
              "rate_calculator_config_change",
              {
                ...trackingPayload,
                error: "unknown error",
              },
            );

            return;
          }

          track(
            "rate_calculator_config_change",
            {
              ...trackingPayload,
              ...response,
            },
          );

          setTentativePrice(response);
        })();

        return () => {
          cancelled = true;
        };
      },
      [enqueueSnackbar, setTentativePrice, session],
    );

  useEffect(
    () => {
      return handleGetTentativePrice(
        property_slug,
        checkin_date,
        checkout_date,
        adult_count,
        child_count,
        coupon_code,
        property_configs,
        avail_loyalty_points,
        display_protect_widget,
        is_refundable_opted,
        protect_quote_id,
      );
    },
    [
      property_slug,
      checkin_date,
      checkout_date,
      adult_count,
      child_count,
      coupon_code,
      avail_loyalty_points,
      property_configs,
    ],
  );

  return {
    tentativePrice: tentativePrice,
  };
}

function usePriceCalculatorValue(id: string) {
  const [tentativePrice] = useAtomState(id, DEFAULT_TENTATIVE_PRICE);

  return {
    tentativePrice: tentativePrice,
  };
}
export default usePriceCalculator;

export {
  usePriceCalculatorValue,
};

const DEFAULT_TENTATIVE_PRICE: TentativePrice = {
  breakdown: {
    gross_base_rate: 0,
    taxes: 0,
    convenience_fee: 0,
    deposit: 0,
    extra_adults: 0,
    extra_adults_fare: 0,
    rental_charges: 0,
    discount: 0,
    discount_message: "",
    total: 0,
    discount_method: "",
    discount_type: "",
    discount_value: 0,
    coupon_code: "",
    loyalty_points_discount_percentage: 0,
    loyalty_points_discount_percentage_value: 0,
    loyalty_points_redeemed_value: 0,
    loyalty_points_earning: 0,
    premium: 0,
  },
  widget_details: {
    protectHtml: "",
    protectQuoteId: "",
  },
  currency_code: "",
  currency_symbol: "",
  discounted_total: 0,
  property_configs: [],
  sold_out: false,
  status: "",
  total: 0,
};
