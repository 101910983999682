import React from "react";
import clxs from "./rating-counts.module.css";
import useClx from "../../../hooks/use-clx";
import RATING_ICON from "../../../assets/icons/rating-icon.svg";

interface RatingCountsProps {
    className?: string;
    average_ratings?:number;
    ratings_count?:number;
    theme?:string;
  }

function RatingCounts(props:RatingCountsProps) {

    const {className,average_ratings,ratings_count,theme} = props;

    const ccx = useClx(clxs.container, className);

    return (
    <div className={ccx}>
        <img 
          src={RATING_ICON} 
          alt="icon"
          className={theme=="collection" ? clxs.ratingIconCollection : clxs.ratingIcon}
        />
        <span className={theme=="collection" ? clxs.avgRatingCollection : clxs.avgRating}>{average_ratings}</span>
        <span className={theme=="collection" ? clxs.ratingCountCollection : clxs.ratingCount}>({ratings_count})</span>
    </div>  );
}

export default RatingCounts;