import React from "react";
import clxs from "./collection-loader.module.css";

interface CollectionLoaderProps {
    isLoading: boolean;
  }

function CollectionLoader(props:CollectionLoaderProps) {
    const { isLoading } = props;

    return (
        isLoading ? (
          <div className={clxs.ldsRipple}>
            <div></div>
            <div></div>
          </div>
        ) : <></>
      );
}

export default CollectionLoader;