import React, { ChangeEvent, useState } from "react";
import CLOSE_ICON from "../../assets/icons/close.svg";
import { useCoupons } from "../../hooks/use-coupons";
import clxs from "./coupon-suggestion.module.css";

interface CouponSuggestionProps {
  propertySlug?: string,
  checkinDate?: string,
  checkoutDate?: string,
  adultCount?: number,
  childCount?: number,
  onSubmit?: (couponCode: string | null) => void;
}

function CouponSuggestion (props: CouponSuggestionProps) {
  const [manual, setManual] = useState<string>(""),
    {
      propertySlug,
      checkinDate,
      checkoutDate,
      adultCount,
      childCount,
      onSubmit,
    } = props,
    { coupons } = useCoupons(
      propertySlug,
      checkinDate,
      checkoutDate,
      adultCount,
      childCount,
    ),
    handleManualChange = (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement,
        { value } = target;

      setManual(value);
    },
    handleCouponSelected = (coupon: string) => {
      return onSubmit?.(coupon);
    };

  return (
    <div className={clxs.container}>
      <div className={clxs.title}>
        Apply coupon
        <img
          src={CLOSE_ICON}
          alt="close"
          className={clxs.close}
          onClick={onSubmit?.bind(null, null)}
        />
      </div>
      <div className={clxs.manualContainer}>
        <input
          type="text"
          className={clxs.manual}
          name="coupon_code"
          placeholder="Type coupon code here"
          value={manual}
          onChange={handleManualChange}
        />
        <button
          className={clxs.manualApply}
          onClick={handleCouponSelected.bind(null, manual)}
        >
          Apply
        </button>
      </div>
      {coupons.length ? (
        coupons.map(
          (each, i) => {
            const { name, code, description } = each;

            return (
              <div
                key={i}
                className={clxs.coupon}
              >
                <div className={clxs.couponTitle}>{name || code}</div>
                <button
                  className={clxs.couponApply}
                  onClick={handleCouponSelected.bind(null, code)}
                >
                  Apply
                </button>
                <div>{description}</div>
              </div>
            );
          },
        )
      ) : (
        <div className={clxs.noOffer}>
          There are no offers available for this villa on the selected dates.
        </div>
      )}
    </div>
  );
}

export default CouponSuggestion;
