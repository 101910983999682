import debounce from "lodash.debounce";
import React, {
  ChangeEvent,
  HTMLAttributes,
  InputHTMLAttributes,
  KeyboardEvent,
  LabelHTMLAttributes,
  MouseEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useClx from "../../hooks/use-clx";
import ExpandIcon from "./ExpandIcon";
import clxs from "./select.module.css";

export interface SelectProps extends InputHTMLAttributes<HTMLInputElement> {
  options: Option[];
  containerProps?: Omit<HTMLAttributes<HTMLDivElement>, "className">;
  label?: string;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  searchable?: boolean;
  defaultValue?: string;
  value?: string;
  error?: string;
  iconMobile?: string;
  iconDesktop?: string;
}

function Select(props: SelectProps) {
  const {
      containerProps = {},
      label,
      labelProps = {},
      options: allOptions,
      searchable = true,
      defaultValue = "",
      onChange,
      onBlur,
      onFocus,
      error,
      iconMobile,
      iconDesktop,
      placeholder: _placeholder,
      ...rest
    } = props,
    { value: _value, name, disabled=false } = rest,
    optionsRef = useRef<HTMLDivElement>(null),
    [value, setValue] = useState<string>(defaultValue),
    placeholder = useMemo(
      () => allOptions.find(each => each.value === value)?.label || _placeholder || "",
      [allOptions, _placeholder, value],
    ),
    [text, setText] = useState<string>(placeholder || ""),
    [focused, setFocused] = useState<boolean>(false),
    [mouseOver, setMouseOver] = useState<boolean>(false),
    [selectedIdx, setSelectedIdx] = useState<number>(
      () => Math.max(
        allOptions.findIndex(
          ({ value: v }) => value === v),
        0,
      ),
    ),
    [options, setOptions] = useState<Option[]>(allOptions),
    { className: _ccx } = rest,
    { className: _lcx } = labelProps,
    ccx = useClx(clxs.container, _ccx),
    lcx = useClx(clxs.label, _lcx),
    icx = useClx(clxs.input, "input"),
    iccx = useClx(clxs.expandIcon, "expand-icon"),
    handleSearchChange = (allOptions: Option[], text: string) => {
      if (!text) {
        setOptions(allOptions);

        return;
      }

      const options = allOptions.filter(
        each => each.label
          .toLowerCase()
          .includes(text.toLowerCase()),
      );

      setOptions(options);
    },
    handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setText(value);

      _dOnSearchChange(handleSearchChange, allOptions, value);
    },
    handleFocus = () => {
      setFocused(true);

      const target = { name: name, id: name, value: value },
        payload = { target: target, currentTarget: target };

      if (!onFocus) {
        return;
      }

      onFocus(payload as any);
    },
    handleValueChange = (value?: string) => {
      if (value === undefined) {
        return;
      }

      setValue(value);

      const selectedIdx = Math.max(
        options.findIndex(({ value: v }) => value === v),
        0,
      );

      setSelectedIdx(selectedIdx);

      setText(options[selectedIdx]?.label || _placeholder || "");
    },
    handleScrollIntoView = (idx: number) => {
      const { current: parent } = optionsRef;

      if (!parent) {
        return;
      }

      if (idx < 0 || idx > parent.childElementCount - 1) {
        return;
      }

      const parentTop = parent.scrollTop,
        parentBottom = parentTop + parent.clientHeight,
        element = parent.children[idx],
        { top, bottom } = element.getBoundingClientRect();

      if (top < parentBottom && bottom > parentTop) {
        return;
      }

      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    },
    handleChange = (option: { label: string, value: string }) => {
      setValue(option.value);

      setText(option.label);

      const target = { name: name, id: name, value: option.value },
        payload = { target: target, currentTarget: target };

      if (!onChange) {
        return;
      }

      onChange(payload as any);
    },
    handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
      const { keyCode } = e;

      if (!focused) {
        // space
        if (keyCode === 32) {
          e.preventDefault();
          setFocused(true);
          return;
        }

        if (keyCode > 64 && keyCode < 91) {
          setFocused(true);
          return;
        }

        return;
      }

      // up
      if (keyCode === 38) {
        e.preventDefault();
        e.stopPropagation();
        const idx = Math.max(0, selectedIdx - 1);
        setSelectedIdx(idx);
        handleScrollIntoView(idx);
        return;
      }

      // down
      if (keyCode === 40) {
        e.preventDefault();
        e.stopPropagation();
        const idx = Math.min(options.length - 1, selectedIdx + 1);
        setSelectedIdx(idx);
        handleScrollIntoView(idx);
        return;
      }

      // esc
      if (keyCode === 27) {
        e.stopPropagation();
        setFocused(false);
        return;
      }

      // enter
      if (keyCode === 13) {
        e.preventDefault();
        setFocused(false);
        handleChange(options[selectedIdx]);
        return;
      }
    },
    handleBlur = () => {
      setFocused(false);
      const target = { name: name, id: name, value: value },
        payload = { target: target, currentTarget: target };

      if (!onBlur) {
        return;
      }

      onBlur(payload as any);
    },
    handleOptionClick = (
      option: { label: string; value: string },
      e: MouseEvent<HTMLDivElement>,
    ) => {
      e.preventDefault();

      setFocused(false);

      handleChange(option);
    },
    handleClick = () => {
      if (focused) {
        return;
      }
      setFocused(true);
    };

  useEffect(
    () => handleSearchChange(allOptions, ""),
    [allOptions],
  );

  useEffect(() => handleValueChange(_value), [_value, options]);

  return (
    <div
      {...containerProps}
      data-focus={focused}
      data-disabled={disabled}
      className={ccx}
      onKeyDown={handleKeyDown}
    >
      {label && (
        <label
          {...labelProps}
          htmlFor={name}
          className={lcx}
        >
          {label}
        </label>
      )}
      <input
        {...rest}
        id={name}
        type="text"
        name={name}
        value={text}
        className={icx}
        autoComplete="off"
        data-error={Boolean(error).valueOf()}
        readOnly={!searchable}
        placeholder={placeholder}
        onBlur={handleBlur}
        onChange={handleTextChange}
        onFocus={handleFocus}
        onClick={handleClick}
        suppressHydrationWarning={true}
      />
      {error && <div className={clxs.error}>{error}</div>}
      <ExpandIcon className={iccx}/>
      {focused && (
        <div
          data-hover={mouseOver}
          className={clxs.options}
          onMouseEnter={setMouseOver.bind(null, true)}
          onMouseLeave={setMouseOver.bind(null, false)}
          ref={optionsRef}
        >
          {options.length ?
            options.map((option, key) => (
              <div
                key={key}
                className={clxs.option}
                data-value={option.value}
                data-focused={selectedIdx === key}
                onMouseDown={handleOptionClick.bind(null, option)}
              >
                {(iconMobile || iconDesktop) ? 
                ((iconMobile ?
                  <img
                    src={iconMobile}
                    alt="icon"
                    className={clxs.iconMobile}
                  /> : 
                  <img
                    src={iconDesktop}
                    alt="icon"
                    className={clxs.iconDesktop}
                  /> ))  
                : null}
                {option.label}
              </div>
            )) : (
              <div className={clxs.noOption}>
                No options
              </div>
            )}
        </div>
      )}
    </div>
  );
}

export default Select;

const _dOnSearchChange = debounce(
  (
    cb: (options: Option[], value: string) => void,
    options: Option[],
    text: string,
  ) => cb(options, text),
  500,
  { trailing: true },
);

type Option = { label: string; value: string };
