import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAnalytics from "../../hooks/use-analytics";
import useIsEH from "../../hooks/use-is-eh";
import Modal, { useShowModal } from "../../hooks/use-modal";
import usePathParams from "../../hooks/use-path-params";
import usePropertyDetail from "../../hooks/use-property-detail";
import useSendRequest from "../../hooks/use-send-request";
import NotFound from "../NotFound";
import Gallery from "./Gallery";
import LohonoBlackLayout from "./LohonoBlackLayout";
import LohonoLayout from "./LohonoLayout";
import { PriceCalculatorContext } from "../../hooks/use-price-calculator";
import { useQueryParamValue } from "../../hooks/use-query-param";

function PropertyDetail () {
  const { pathname, search } = useLocation(),
    push = useNavigate(),
    showGalleryModal = useShowModal(GALLERY_MODAL_ID),
    { showSendRequestModal } = useSendRequest(),
    { track } = useAnalytics(),
    destination = usePathParams(PATH, "destination"),
    location = usePathParams(PATH, "location"),
    property_slug = usePathParams(PATH, "property"),
    checkin_date = useQueryParamValue("date", CHECKIN_DATE_KEY),
    checkout_date = useQueryParamValue("date", CHECKOUT_DATE_KEY),
    location_slug = useMemo(
      () => `${destination}-${location}`,
      [destination, location],
    ),
    { isEH } = useIsEH(location_slug),
    { propertyDetail } = usePropertyDetail(property_slug, checkin_date, checkout_date),
    isBlack = useMemo(
      () => propertyDetail?.brand === "lohono_black",
      [propertyDetail],
    ),
    handleShowGallery = () => showGalleryModal({ images: propertyDetail?.images ?? [] }),
    handleShare = async () => {
      if (!propertyDetail) {
        return true;
      }

      const url = window.location.href, share = {
        title: propertyDetail.name,
        text: propertyDetail.description || "Luxury Lohono Villa",
        url: url,
      };

      try {
        await window.navigator.share(share);
      } catch (err) {
        await window.navigator.clipboard.writeText(url);
        return false;
      }

      return true;
    },
    handleSendRequest = (values: any = {}) => {
      return showSendRequestModal({
        interest: "rental",
        location: location_slug,
        meta: values,
      });
    },
    handleBook = async (values: any) => {
      track("property_detail_book_clicked");

      if (isEH) {
        return handleSendRequest(values);
      }

      const checkoutUrl = `${pathname}/checkout`;

      return push({
        pathname: checkoutUrl,
        search: search,
      });
    };

  if (!propertyDetail) {
    return <NotFound />;
  }

  return (
    <PriceCalculatorContext
      path={PATH}
      id={PRICE_CALCULATOR_ID}
      propertySlugKey={PROPERTY_SLUG_KEY}
      checkinDateKey={CHECKIN_DATE_KEY}
      checkoutDateKey={CHECKOUT_DATE_KEY}
      adultCountKey={ADULT_COUNT_KEY}
      childCountKey={CHILD_COUNT_KEY}
      couponCodeKey={COUPON_CODE_KEY}
      configs={propertyDetail.configs}
      availLoyaltyPointsKey={AVAIL_LOYALTY_POINTS_KEY}
    >
      <>
        {isBlack ? (
          <LohonoBlackLayout
            propertyDetails={propertyDetail}
            onShowGallery={handleShowGallery}
            onShare={handleShare}
          />
        ) : (
          <LohonoLayout
            priceCalculatorId={PRICE_CALCULATOR_ID}
            path={PATH}
            locationSlug={location_slug}
            propertyDetails={propertyDetail}
            onShowGallery={handleShowGallery}
            onShare={handleShare}
            onSendRequest={handleSendRequest}
            onBook={handleBook}
            propertySlugKey={PROPERTY_SLUG_KEY}
            checkinDateKey={CHECKIN_DATE_KEY}
            checkoutDateKey={CHECKOUT_DATE_KEY}
            adultCountKey={ADULT_COUNT_KEY}
            childCountKey={CHILD_COUNT_KEY}
            couponCodeKey={COUPON_CODE_KEY}
            availLoyaltyPointsKey={AVAIL_LOYALTY_POINTS_KEY}
            seniorCitizenDescription={propertyDetail.senior_citizen.description}
            seniorCitizenFriendly={propertyDetail.senior_citizen.senior_citizen_friendly}
          />
        )}
        <Modal id={GALLERY_MODAL_ID}>
          <Gallery />
        </Modal>
      </>
    </PriceCalculatorContext>
  );
}

export default PropertyDetail;

const GALLERY_MODAL_ID = "property-gallery-mobile";

const PATH = "/villas/:destination/:location/:property";

const PROPERTY_SLUG_KEY = "property";

const CHECKIN_DATE_KEY = "checkin_date";

const CHECKOUT_DATE_KEY = "checkout_date";

const ADULT_COUNT_KEY = "adult_count";

const CHILD_COUNT_KEY = "child_count";

const COUPON_CODE_KEY = "coupon_code";

const AVAIL_LOYALTY_POINTS_KEY = "avail_loyalty_points";

const PRICE_CALCULATOR_ID = "property-details-price-calculator";
