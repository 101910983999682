import * as Yup from "yup";
import SINGLE_WEEKEND_VALIDATION from "../../../../../../utils/single-weekend-validation";
import { generatePropertyConfigKey } from "../../../../../hooks/use-property-config";

const PRICE_CALCULATOR_SCHEMA1 = Yup.object({
  stay: Yup.object()
    .shape({
      start: Yup.date().nullable(true).required("checkin is required"),
      end: Yup.date().nullable(true).required("checkout is required"),
    }).test(SINGLE_WEEKEND_VALIDATION),
  occupancy: Yup.object({
    adult_count: Yup.number()
      .integer("should be an integer")
      .moreThan(0, "Adults should be greater than 0")
      .required("Required"),
    child_count: Yup.number()
      .integer("should be an integer")
      .moreThan(-1, "Children should be greater than -1"),
  }),
});

const PRICE_CALCULATOR_SCHEMA2 = Yup.object({
  stay: Yup.object()
    .shape({
      start: Yup.date().nullable(true).required("checkin is required"),
      end: Yup.date().nullable(true).required("checkout is required"),
    }),
  occupancy: Yup.object({
    adult_count: Yup.number()
      .integer("should be an integer"),
    child_count: Yup.number()
      .integer("should be an integer")
      .moreThan(-1, "Children should be greater than -1"),
  }),
});

export {
  PRICE_CALCULATOR_SCHEMA1,
  PRICE_CALCULATOR_SCHEMA2,
  createPriceCalculatorSchema,
};

function createPriceCalculatorSchema(
  propertySlug: string,
  configs: PropertyConfig[],
  soldOut: boolean,
  viewLayout: string,
) {
  let adultCountSchema = Yup.number().integer("should be valid");

  if (!soldOut) {
    adultCountSchema = adultCountSchema
      .moreThan(0, "Please select at least one adult")
      .required("Required");
  }

  const configSchemaPartial = configs.reduce(
    (compiled, config) => {
      const key = generatePropertyConfigKey(propertySlug, config);

      return {
        ...compiled,
        [key]: Yup.number()
          .integer("should be a valid selection")
          .min(0, "should be at least 0"),
      };
    },
    {} as Record<string, Yup.AnySchema>,
  );

  const configSchema = Yup.object()
    .shape(configSchemaPartial)
    .test(
      "required",
      "at least one room should be selected",
      (value) => {
        const roomCount = configs.reduce(
          (roomCount, config) => {
            const key =  generatePropertyConfigKey(propertySlug, config);

            return roomCount + (value[key] ?? 0);
          },
          0,
        );

        return roomCount > 0;
      },
    );

  return Yup.object({
    stay: Yup.object()
      .shape({
        start: Yup.date().nullable(true).required("Checkin is required"),
        end: Yup.date().nullable(true).required("Checkout is required"),
      }).test(SINGLE_WEEKEND_VALIDATION),
    occupancy: Yup.object({
      adult_count: adultCountSchema,
      child_count: Yup.number()
        .integer("should be an integer")
        .moreThan(-1, "Please select at least 0 children"),
    }),
    pre_book_meals: Yup.boolean(),
    ...(viewLayout == "multi_config") && {config_args: configSchema},
  });
}
