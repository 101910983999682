const OUTRO_TEMPLATE = {
  "india-goa": "It’s not just the Goa you know; it’s more than beautiful beaches and cookie-cutter resorts. We have created a portfolio of opulent holiday villas surrounded by greenery, sea and sand. We have explored Goa’s rich history and heritage and brought it into our private bungalows for rent. We have positioned ourselves near the best in food, culture and introduced to Goa the #LohonoLife amidst its subtle charm. All our luxury villas in Goa for rent are situated in the rich but secluded locales of Assagao, Siolim, Vagator, Anjuna, and Dona Paula. However, it is just a drive away from popular restaurants and places.\n\nOur holiday villas on rent have a private pool, a lawn and all the amenities you need for a fun and relaxing time away from the city. You can recharge yourselves at our private villas before you go back to your busy schedule. Experience exceptional luxury in Lohono’s Goa.",
  "india-mahabaleshwar": "If you’re craving a getaway filled with beautiful weather and verdant mountains, there is no place better suited for you than Lohono Stays’ luxury bungalows and villas in Mahabaleshwar. We bring you a fantastic collection of private villas for rent to relax in the luxury of this evergreen hill station. Lovely gardens, cozy bedrooms and incredible amenities… Find the perfect blend of luxury in a private villa in Mahabaleshwar and put your mind at ease. Experience luxury and tranquillity as you live #TheLohonoLife at Lohono’s bungalows and villas on rent in Mahabaleshwar.",
  "india-bhimtal": "Whether it’s a summer getaway or an unforgettable winter vacation, Lohono Stays offers the best villas on rent in Bhimtal. Experience stunning views, natural beauty and incredible adventures, all packed into one. Our luxury villas for rent in Bhimtal are ideal for rejuvenating get-togethers with family or friends. If your idea of a perfect hill-station retreat includes verdant forests, beautiful hills and a luxurious place to stay in, then make your vacation a reality with us! Look no further and book Lohono Stays’ private villas in Bhimtal.",
  "maldives-maldives": "Get ready for an incredible escape with Lohono Stays. Surrounded by pristine beaches and palm trees, make your dream vacation come true with a stay at Maldives’ private beach villas. Bask in the natural beauty, embrace the vibrant culture and enjoy the luxurious holiday lifestyle at your Maldives luxury villas for rent. Leave all your worries behind and dive into an unforgettable vacation with Maldives’ private resorts filled with a multitude of amenities. When looking for holiday villas in Maldives, look no further and give in to #TheLohonoLife.",
  "srilanka-sri-lanka": "Witness the glory of the 'Pearl of the Indian Ocean’ at Lohono Stays’ luxury villas for rent in Sri Lanka. Incredible natural beauty, scenic vistas, modern amenities and comforts in your very own private villas in Sri Lanka. Live #TheLohonoLife with fantastic beaches, glistening waters and luxury private beach villas in Sri Lanka. Experience the best of all worlds, from luxury to relaxation and renew your mind, body and soul at Lohono’s holiday villas in Sri Lanka.",
  "india-alibaug": "Situated just a few kilometres away from Mumbai, lies the beautiful city Alibaug - also called The Hamptons of Mumbai. A haven known for its quaint beaches, natural beauty, craft museums and close proximity to Mumbai. We have chosen the best spots to curate our magnificent & luxury villas and bungalows where you and your loved ones can relax and watch the world go by. Our private villas are located in Kihim and Zirad which are only 15 minutes from the Jetty.\n\nOur luxury villas on rent in Alibaug are equipped with a private swimming pool and everything you may require for a comfortable stay. We aim to ensure that all our guests at our luxury villas have a memorable holiday in Lohono’s Alibaug",
  "india-karjat": "An antidote to the mundane city life, Lohono’s villas on rent in Karjat are ideal if you are looking for a quick and luxurious getaway. Karjat is not just any ordinary destination, it’s a destination of hills that will lure you in. Enjoy the crisp air while sipping some hot cocoa on the balcony of your private Karjat farmhouse. Lohono's villas for rent in Karjat are secluded to ensure privacy and can help you escape to a place where all your needs are taken care of.",
  "india-coonoor": "Enjoy the pleasant weather of the Nilgiris as you step back in time in charming Coonoor. Located in Tamil Nadu, you can rent the best cottages in Coonoor that are drenched in luxury and provide a home-like experience. Nature lovers can make the most of the verdant environment of the Nilgiri Hills by birdwatching, exploring Coonoor's tea gardens and hiking the beautiful trails lined with wildflowers.",
  "india-lonavala": "Lonavala is a town defined by rolling green hills, a pleasant climate and ancient rock-cut caves. With activities like trekking, paragliding and fishing, Lonavala is the most sought-after destination to get bungalows on rent for weekend getaways. Our luxury villas on rent in Lonavala are spectacular retreats nestled in the mighty Sahyadri range in Maharashtra. The crisp air and scenic beauty make it an ideal destination to rent a private villa and spend quality time with your family or friends. Our villas in Lonavala blend comfort and warm hospitality with luxury, giving you a much-needed break, away from the city.",
  "india-mussoorie": "Mussoorie has rightfully earned the title of The Queen of Hills, with some of the most promising views that are known to enchant travellers. Rent a private villa in Mussoorie and let the scents of pine and cedar forests fill up your senses while you take in the panoramic views of the green Doon Valley and the distant snow-capped Himalayan peaks. Stay at Lohono’s luxury villas in Mussoorie and let us take care of all the details while you unwind with loved ones and enjoy the many offbeat activities that Mussoorie has to offer.",
  "india-kasauli": "Nestled in the verdant mountains of Himachal, Lohono Stays brings you the best homestays in Kasauli that promise unforgettable vacations. Immerse yourself in nature and experience true luxury at our best luxury villas for rent in Kasauli. Explore local sights, historic monuments, and nature trails and return to be pampered at our amazing villa stays in Kasauli. Reconnect with your loved ones on a laid-back, luxe holiday in our private villas in Kasauli with ample spaces to lounge and unwind. Unleash your inner wanderer and make the most of Lohono’s Kasauli.",
  // "india-ooty": "Experience good vibes and pure bliss in Ooty, the Queen of Hills, with Lohono Stays. From a luxury villa in Ooty for rent to a private pool villa in Ooty, this hill station has plenty of offerings to ignite the wanderlust in you. Lohono Stays brings you the best villas in Ooty, where you can experience the town’s serene weather or opt for holiday villas in Ooty overlooking gorgeous tea plantations. Our Ooty villas for rent have everything you need, from comfort to luxury, making your stay both relaxing and enjoyable. Planning a trip with your extended family or large group of friends? Our private villas in Ooty for group stay offer plenty of room for you and your squad, so you have the best of both worlds — privacy and the chance to spend quality time with your loved ones.",
  "india-rishikesh": "Set against the backdrop of the mighty Himalayas and surrounded by the natural beauty of the Ganga,the luxury villas in Rishikesh are calling. We bring you the most exotic private villas in Rishikesh for rent,where you can create memories for a lifetime with a large group of friends or family. Pack your bags and book a villa in Rishikesh with Lohono Stays, where you can experience true peace amidst nature. Opt for a riverside villa in Rishikesh, where you can wake up to the pleasant sounds of the flowing river, or a private pool villa in Rishikesh for some much-needed me-time in the lap of luxury. Our holiday homes in Rishikesh are also well connected to the city, so that you can set off on some thrilling adventures. Find the perfect luxury villa for rent in Rishikesh and prepare to enjoy a fun-filled, blissful holiday.",
  "indonesia-bali": "If there is one paradise on earth, Bali is undoubtedly it. With a variety of luxury villas to rent in Bali on the beaches and villas with private pools, find your ideal vacation rentals amidst the island’s incredible natural beauty. Lohono’s private villas in Bali provide everything you need for a fun and relaxing vacation. Come, experience bliss at our luxury villa rentals on this diverse island!",
  "thailand-phuket": "Coupled with large glass windows and sweeping views of the sea, our luxurious Phuket waterfront properties for rent are a sight to behold. Lohono Stays brings you the perfect blend of luxury and tranquillity in Phuket with beachfront villas and luxury houses for rent. Enjoy your dream holiday villa in Phuket with a private pool along with exotic beaches, vibrant nightlife and authentic Thai cuisine. Our luxury holiday homes and private villas for rent provide all the amenities for a comfortable stay while you experience the culture, architecture, bustling markets and abundant activities. There is no better way to holiday in Phuket than to seek out our luxury villas and beach houses for rent!",
  "thailand-koh-samui": "Sunny beaches, luxury villas and blue skies… if this is your dream vacation, then our Koh Samui holiday home rentals are a win! Whether it’s a villa in Koh Samui with a private pool or a beachfront villa for rent or a blend of both in the form of a beachfront villa with a private pool; the list is endless! The charm of the beach villas will lull you into a relaxed state of mind as our world-class villa amenities cater your comfort above all. Your search for the best private villas for rent in Koh Samui ends here!",
};

export default OUTRO_TEMPLATE;
