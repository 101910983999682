import React, { useMemo } from "react";
import useClx from "../../../hooks/use-clx";
import { Track } from "../../AnalyticsManager";
import clxs from "./tier-benefit.module.css";
import LOHONO_LOGO_SILVER from "../../../assets/loyalty-points/lohono-logo-silver.svg";
import LOHONO_LOGO_GOLD from "../../../assets/loyalty-points/lohono-logo-gold.svg";
import LOHONO_LOGO_PLATINUM from "../../../assets/loyalty-points/lohono-logo-platinum.svg";
import LOCK_SILVER from "../../../assets/loyalty-points/card-lock-silver.svg";
import LOCK_GOLD from "../../../assets/loyalty-points/card-lock-gold.svg";
import LOCK_PLATINUM from "../../../assets/loyalty-points/card-lock-platinum.svg";
import CARD_UNLOCK from "../../../assets/loyalty-points/card-unlock.svg";
import CONGRATULATION_ICON from "../../../assets/loyalty-points/congratulation-icon.svg";
import { AnchorButton } from "../../Button";
import useUserWithInitials from "../../../hooks/use-user-with-initials";

interface TierBenefitProps {
  tier: LoyaltyPointsTier;
  nightsCompleted?: number;
  status: string;
  hideNotIncluded?: boolean;
  className?: string;
  isTierMainHeader?: boolean; // Add the prop
}

function TierBenefit (props: TierBenefitProps) {
  const {
    tier,
    status,
    nightsCompleted,
    hideNotIncluded = false,
    className,
    isTierMainHeader, // Destructure the prop
  } = props;

  const { lohonoLogoUrl, cardLockUrl } = useMemo(
    () => {
      const { value } = tier;

      const cardLockUrl = status === ""
        ? CARD_UNLOCK
        : (CARD_LOCK[value] ?? "");

      return {
        lohonoLogoUrl: LOHONO_LOGO[value] ?? "",
        cardLockUrl: cardLockUrl,
      };
    },
    [tier.value, status],
  );

  const overlayVisible = useMemo(
    () => status && status !== "pending",
    [status],
  );

  const pendingNightsContent = useMemo(
    () => {
      if (!nightsCompleted) {
        return null;
      }

      const pendingNights = tier.max_nights - nightsCompleted;

      if (pendingNights < 2) {
        return "1 night";
      }

      return `${pendingNights} nights`;
    },
    [tier, nightsCompleted],
  );

  const ccx = useClx(clxs.container, className);

  const ctacx = useClx(clxs.explore, "cta");

  const { fullName } = useUserWithInitials();

  return (
    <div
      className={ccx}
      data-tier={tier.value}
      data-status={status}
    >
      <div className={clxs.card}>
        <svg
          viewBox="0 0 280 123"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={clxs.cardOverlay}
        >
          <path
            opacity="0.08"
            d="M279.604 123C279.903 82.1439 280.321 0.345263 279.604 0C193.84 67.6478 58.3887 67.2128 0 58.2584V123H279.604Z"
            fill="black"
          />
        </svg>
        <img
          src={lohonoLogoUrl}
          alt="lohono"
          className={clxs.logo}
        />

      {isTierMainHeader ? (
        <div className={clxs.cardTitle}>{fullName}</div>) : (
        <div className={clxs.cardTitle}>
          Lohono Infinity
        </div>
      )}
        
        <img
          src={cardLockUrl}
          alt="locked"
          className={clxs.cardLock}
        />
        <div className={clxs.cardSubtitle}>
          {`${tier.friendly_name} Member`}
        </div>
      </div>
      <div className={isTierMainHeader === true ? clxs.tiermodule : clxs.benefitsContainer}>
        <div className={ isTierMainHeader === true ? clxs.tiermodulebenefits :clxs.benefits}>
          <div className={clxs.cardGap}>
            &nbsp;
          </div>
          <div className={isTierMainHeader === true ? clxs.tiermoduletitle : clxs.title}>
            {`${tier.friendly_name} Member Benefits`}
          </div>
          {tier.benefits.map(
            (each, key) => (
              !hideNotIncluded || each.included ? (
                <div
                  key={key}
                  className={isTierMainHeader === true ? clxs.tiermodulebenefit :clxs.benefit}
                  data-included={each.included}
                >
                  <img
                    src={each.icon}
                    alt={each.label}
                  />
                  {each.label}
                </div>
              ) : null
            ),
          )}
        </div>

        {isTierMainHeader ? (
          <Track event="loyalty_points_next_stay_clicked">
            <AnchorButton
              className={clxs.book}
              href="/#locations"
            >
            Find Your Next Stay
            </AnchorButton>
          </Track>
          ) : (
          <Track event="loyalty_points_explore_now_clicked">
           <a
             href="/#locations"
             className={ctacx}
           >
            Explore Now
           </a>
          </Track>
         )}
        
      </div>
      <div
        className={clxs.benefitContainerOverlay}
        data-visible={overlayVisible}
      >
        {status === "completed" ? (
          <>
            <img
              src={CONGRATULATION_ICON}
              alt="congratulations"
            />
            <div className={clxs.overlayContent}>
              <b>Congratulations!!</b><br />
              {" You've been upgraded to\n"}
              <span className={clxs.membership}>
                Gold Membership
              </span>
            </div>
          </>
        ) : status === "ongoing" ? (
          <>
            <div className={clxs.overlayContent}>
              <b>{pendingNightsContent}</b>
              {" to complete\n"}
              <span
                className={clxs.membership}
                data-color="true"
              >
                {`${tier.friendly_name} Membership`}
              </span>
              
              {/* <div className={clxs.progressbarContainer}>
               <div className={clxs.progressbar}>
                  <div
                    className={clxs.progressbarfill}
                    style={{ transform: `translateX(${tier.max_nights-(nightsCompleted ?? 0)}%)` }}
                  >   
                    </div>
                </div>
              </div> */}
              
            </div>
            
            <Track event="loyalty_points_next_stay_clicked">
              <a
                href="/#locations"
                className={clxs.nextStay}
              >
                Find your next stay
              </a>
            </Track>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default TierBenefit;

const LOHONO_LOGO = {
  silver: LOHONO_LOGO_SILVER,
  gold: LOHONO_LOGO_GOLD,
  platinum: LOHONO_LOGO_PLATINUM,
};

const CARD_LOCK = {
  silver: LOCK_SILVER,
  gold: LOCK_GOLD,
  platinum: LOCK_PLATINUM,
};
