import React, { useMemo } from "react";
import { useField } from "../../../../hooks/use-form";
import AsyncDateRangeInput, { AsyncDateRangeInputProps } from "../../../DateRangeInput/AsyncDateRangeInput";

interface AsyncDateRangeProps extends AsyncDateRangeInputProps {
  form: string;
  name: string;
}

function AsyncDateRange (props: AsyncDateRangeProps) {
  const {
      form,
      name,
      ...rest
    } = props, {
      value,
      error: err,
      handleChange,
    } = useField(form, name),
    { error, hasError } = useMemo(
      () => {
        if (!err) {
          return {
            error: "",
            hasError: false,
          };
        }

        // noinspection SuspiciousTypeOfGuard
        if (typeof err === "string") {
          return {
            error: err,
            hasError: err.length > 0,
          };
        }

        const { start, end } = err as any,
          errors: string[] = [];

        if (start) {
          errors.push(start);
        }

        if (end) {
          errors.push(end);
        }

        return {
          hasError: errors.length > 0,
          error: errors.join(", "),
        };
      },
      [err],
    );

  return (
    <AsyncDateRangeInput
      {...rest}
      name={name}
      start={value?.start}
      end={value?.end}
      helperText={error}
      error={hasError}
      onChange={handleChange}
    />
  );
}

export default AsyncDateRange;
