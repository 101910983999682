const DOMESTIC_PROPERTY_LOCATIONS = [
  {
    header: "Luxury villas in Goa",
    path: "/villas/india/goa",
    annotation: "domestic",
    meta: {
      type: "location",
      destination: "india",
      location: "goa",
    },
  },
  {
    header: "Luxury villas in Srinagar/Pahalgam",
    path: "/villas/india/srinagar",
    annotation: "domestic",
    meta: {
      type: "location",
      destination: "india",
      location: "srinagar",
    },
  },
  {
    header: "Luxury villas in Jim Corbett",
    path: "/villas/india/jimcorbett",
    annotation: "domestic",
    meta: {
      type: "location",
      destination: "india",
      location: "jimcorbett",
    },
  },
  {
    header: "Luxury villas in Jaipur",
    path: "/villas/india/jaipur",
    annotation: "domestic",
    meta: {
      type: "location",
      destination: "india",
      location: "jaipur",
    },
  },
  {
    header: "Luxury villas in Gurgaon",
    path: "/villas/india/gurgaon",
    annotation: "domestic",
    meta: {
      type: "location",
      destination: "india",
      location: "gurgaon",
    },
  },
  {
    header: "Luxury villas in Rishikesh",
    path: "/villas/india/rishikesh",
    annotation: "domestic",
    meta: {
      type: "location",
      destination: "india",
      location: "rishikesh",
    },
  },
  // {
  //   header: "Luxury villas in Ooty",
  //   path: "/villas/india/ooty",
  //   annotation: "domestic",
  //   meta: {
  //     type: "location",
  //     destination: "india",
  //     location: "ooty",
  //   },
  // },
  // {
  //   header: "Luxury villas in Kasauli",
  //   path: "/villas/india/kasauli",
  //   annotation: "domestic",
  //   meta: {
  //     type: "location",
  //     destination: "india",
  //     location: "kasauli",
  //   },
  // },
  {
    header: "Luxury villas in Alibaug",
    path: "/villas/india/alibaug",
    annotation: "domestic",
    meta: {
      type: "location",
      destination: "india",
      location: "alibaug",
    },
  },
  {
    header: "Luxury villas in Lonavala/Khandala",
    path: "/villas/india/lonavala",
    annotation: "domestic",
    meta: {
      type: "location",
      destination: "india",
      location: "lonavala",
    },
  },
  {
    header: "Luxury villas in Mussoorie/Dehradun",
    path: "/villas/india/mussoorie",
    annotation: "domestic",
    meta: {
      type: "location",
      destination: "india",
      location: "mussoorie",
    },
  },
  {
    header: "Luxury villas in Mahabaleshwar",
    path: "/villas/india/mahabaleshwar",
    annotation: "domestic",
    meta: {
      type: "location",
      destination: "india",
      location: "mahabaleshwar",
    },
  },
  {
    header: "Luxury villas in Karjat",
    path: "/villas/india/karjat",
    annotation: "domestic",
    meta: {
      type: "location",
      destination: "india",
      location: "karjat",
    },
  },
  {
    header: "Luxury villas in Coonoor",
    path: "/villas/india/coonoor",
    annotation: "domestic",
    meta: {
      type: "location",
      destination: "india",
      location: "coonoor",
    },
  },
  {
    header: "Luxury villas in Coorg",
    path: "/villas/india/coorg",
    annotation: "domestic",
    meta: {
      type: "location",
      destination: "india",
      location: "coorg",
    },
  },
  {
    header: "Luxury villas in Bhimtal",
    path: "/villas/india/bhimtal",
    annotation: "domestic",
    meta: {
      type: "location",
      destination: "india",
      location: "bhimtal",
    },
  },
  {
    header: "Luxury villas in Pench",
    path: "/villas/india/pench",
    annotation: "domestic",
    meta: {
      type: "location",
      destination: "india",
      location: "pench",
    },
  },
  {
    header: "Luxury villas in Udaipur",
    path: "/villas/india/udaipur",
    annotation: "domestic",
    meta: {
      type: "location",
      destination: "india",
      location: "udaipur",
    },
  },
];

const INTERNATIONAL_PROPERTY_LOCATIONS = [
  {
    header: "Luxury villas in Maldives",
    path: "/villas/maldives/maldives",
    annotation: "international",
    meta: {
      type: "location",
      destination: "maldives",
      location: "maldives",
    },
  },
  {
    header: "Luxury villas in Bali",
    path: "/villas/indonesia/bali",
    annotation: "international",
    meta: {
      type: "location",
      destination: "indonesia",
      location: "bali",
    },
  },
  {
    header: "Luxury villas in Phuket",
    path: "/villas/thailand/phuket",
    annotation: "international",
    meta: {
      type: "location",
      destination: "thailand",
      location: "phuket",
    },
  },
  {
    header: "Luxury villas in Koh Samui",
    path: "/villas/thailand/koh-samui",
    annotation: "international",
    meta: {
      type: "location",
      destination: "thailand",
      location: "koh-samui",
    },
  },
  {
    header: "Luxury villas in Sri Lanka",
    path: "/villas/srilanka/sri-lanka",
    annotation: "international",
    meta: {
      type: "location",
      destination: "srilanka",
      location: "sri-lanka",
    },
  },
];

export {
  DOMESTIC_PROPERTY_LOCATIONS,
  INTERNATIONAL_PROPERTY_LOCATIONS,
};