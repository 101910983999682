import React, { Fragment } from "react";
import useTnc from "../../hooks/use-tnc";
import clxs from "./tnc.module.css";

function TNC() {
  const { tnc } = useTnc();

  return (
    <>
      <h1>Terms and Conditions</h1>
      {tnc.map(
        (each, key) => {
          const { title, content } = each;

          return (
            <Fragment key={key}>
              <div
                className={clxs.title}
                id={title.replaceAll(",", "").replaceAll(" ", "-").toLowerCase()}
              >
                {title}
              </div>
              {content.map(
                (each, key) => (
                  <p
                    key={key}
                    className={clxs.content}
                  >
                    {each}
                  </p>
                ),
              )}
            </Fragment>
          );
        },
      )}
    </>
  );
}

export default TNC;
