import { useAtomState } from "./use-atom";

class _DefaultStaticContentService implements IStaticContentService {
  async getFAQ (): Promise<FetchResult<FAQ[]>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getStaticData (): Promise<FetchResult<StaticData>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getPhoneExt (): Promise<FetchResult<PhoneExt[]>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getPrivacyPolicy (): Promise<FetchResult<StaticContentSection[]>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getSitemap (): Promise<FetchResult<SitemapPartial[]>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getTnc (): Promise<FetchResult<StaticContentSection[]>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getLoyaltyPointsPolicy (): Promise<FetchResult<LoyaltyPointsPolicy>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

}

const DefaultStaticContentService = new _DefaultStaticContentService();

export const STATIC_SERVICE_KEY = "STATIC_SERVICE";

function useStaticContentService () {
  const [service, setService] =
    useAtomState<IStaticContentService>(
      STATIC_SERVICE_KEY,
      DefaultStaticContentService,
    );

  return {
    service: service,
    setService: setService,
  };
}

export default useStaticContentService;
