import React, {useMemo} from "react";
import NativeOccupancy, {
  OccupancyProps as NativeOccupancyProps,
} from "../../../Occupancy";
import {useField} from "../../../../hooks/use-form";

interface OccupancyProps extends BaseOccupancyProps {
  form: string;
  name: string;
}

function Occupancy(props: OccupancyProps) {
  const {
      form,
      ...rest
    } = props,
    {name} = rest,
    {
      value,
      error: err,
      setFieldValue,
    } = useField(form, name),
    error = useMemo(
      () => {
        if (!err) {
          return "";
        }

        if (typeof err === "string") {
          return err;
        }

        const {
            adult_count,
            child_count,
          } = err as any,
          errors: string[] = [];

        if (adult_count) {
          errors.push(adult_count);
        }

        if (child_count) {
          errors.push(child_count);
        }

        return errors.join(", ");
      },
      [err],
    );

  return (
    <NativeOccupancy
      {...rest}
      value={value}
      error={error}
      onChange={setFieldValue.bind(null, name)}
    />
  )
}

export default Occupancy;


type BaseOccupancyProps = Omit<NativeOccupancyProps, "value" | "onChange" | "onBlur" | "error">;
