import React, { ForwardedRef, forwardRef, useCallback, useMemo, useRef, useState } from "react";
import clxs from "./guest-reviews.module.css";
import READ_MORE from "../../../assets/read-more-chevron.svg";
import READ_LESS from "../../../assets/read-less-chevron.svg";
import useClx from "../../../hooks/use-clx";
import Stars from "./Stars";
import ReviewItem from "./ReviewItem";
import Collapse from "../../Collapse";
import Modal, { useShowModal } from "../../../hooks/use-modal";
import GuestReviewModal from "./GuestReviewModal";

interface GuestReviewsProps {
  reviews: Reviews[];
  className?: string;
  ratings_count?: number;
  average_ratings?: number;
  isSticky?: boolean;
  title: string;
  configs: PropertyConfig[],
  onViewAll?: (config: PropertyConfig) => void;
}

// Forwarded ref component for Guest Reviews
const GuestReviews = forwardRef(
  function GuestReviewsCore(
    props: GuestReviewsProps,
    ref: ForwardedRef<HTMLDivElement>,
  ){
  const {
      reviews,
      className,
      average_ratings ,
      ratings_count ,
      isSticky,
      title,
    } = props,
    ccx = useClx(clxs.container, className),
    [collapsed, setCollapsed] = useState<boolean>(true),
    ratingReviews = useMemo(() => reviews.slice(0, 2), [reviews]),// Memoize the first two reviews
    showguestReviewModal = useShowModal(GUEST_REVIEW_MODAL_ID),
    handleToggle = useCallback(() => {
      isSticky && collapsRef.current?.scrollIntoView(),
        setCollapsed((collapsed) => !collapsed);
    }, [setCollapsed]),
    tcx = useClx(
      isSticky ? clxs.stickyContainer : clxs.titleContainer,
      "title",
    ),

    handleGuestReviewsModal = () => {
      showguestReviewModal({});
    },
    cccx = useClx(clxs.collapse, "content"),
    collapsRef = useRef<HTMLUListElement>(null);

  return (<>
    <div 
      className={ccx}
      ref={ref}
    >
      <div className={clxs.header}>
        <div 
          className={tcx} 
          onClick={handleToggle}
        >
          {title}
          <img
            className={clxs.readMoreIcon}
            src={collapsed ? READ_LESS : READ_MORE}
            alt="read-more"
          />
        </div>
      </div>
     
        <div
          className={collapsed ? clxs.guestRatingsCollaps : clxs.guestRatings}
        >

          {average_ratings ? 
          <div className={clxs.avgRating}>{average_ratings}</div>
          : null }

          {average_ratings ? 
          <Stars 
            className={clxs.stars} 
            average_ratings={average_ratings}
          /> 
          : null } 
          
          {ratings_count ?
          <div className={clxs.ratingCount}>{ratings_count} Reviews</div> 
          : null }
          
        </div> 
      <ReviewItem
       // Pass the sliced reviews array for web
        ratingReviews={ratingReviews} 
        className={clxs.reviewItem} 
      />
      <Collapse
        expanded={collapsed}
        className={cccx}
        outerClassName="collapse-container"
      >
        <ReviewItem
        // Pass the sliced reviews array for responsive
          ratingReviews={ratingReviews} 
          className={clxs.reviewItemCollaps}
        />
      </Collapse>
      {/* if reviews array is less than 2 then hide view all  */}
      {reviews.length > 2 ? 
      <div 
        className={collapsed ? clxs.viewAllCollaps : clxs.viewAll}
        onClick={handleGuestReviewsModal}
      >
        {"View All Reviews"}
      </div> : null }
    </div>
    {/* while clicking on view all, guest review modal gets open */}
    <Modal id={GUEST_REVIEW_MODAL_ID}>
      <GuestReviewModal
        reviews={reviews}
        average_ratings={average_ratings}
        ratings_count={ratings_count}
        title="Guest Reviews"
      />
    </Modal>
    </>
  );
})

export default GuestReviews;

const GUEST_REVIEW_MODAL_ID = "guest-details-modal"

