import React, { ForwardedRef, forwardRef } from "react";
import clxs from "./landing-page-form.module.css";
import useClx from "../../hooks/use-clx";
import ImmediateAssistanceForm from "./ImmediateAssistanceForm";

interface LandingpageFormProps {
  className?: string;
  title?: string;
  subtitle?: string;
  interest?: string;
  brand?: number;
  formId: string;
}

  const LandingpageForm = forwardRef(
    function LandingpageFormCore(
      props:LandingpageFormProps,
      ref: ForwardedRef<HTMLDivElement>,
    ){
    const { className, title,formId } = props,
    defaultValue = {
      full_name: "",
      phone_ext: "+91",
      phone_no: "",
      email: "",
      interest: "rental",
      location: "goa",
      message: "",
    },
    ccx = useClx(clxs.container, className);

  return (
    <div 
      className={ccx}
      ref={ref}
    >
      <ImmediateAssistanceForm
        id={formId}
        className={clxs.sendRequest}
        titleNode={<div className={clxs.sendRequestTitle}>{title}</div>}
        defaultValue={defaultValue}
        // isClearOnSubmit={true}
      />
    </div>
  );
},
)

export default LandingpageForm;
