import React, { useCallback, useMemo } from "react";
import useFingerprint from "../../hooks/use-fingerprint";
import Modal, { useShowModal } from "../../hooks/use-modal";
import usePathParams from "../../hooks/use-path-params";
import { useSnackbar } from "../../hooks/use-snackbar";
import Alert from "../Alert";
import Button from "../Button";
import useFeedbackArgs from "./use-feedback-args";
import Form from "../Form";
import { Select, TextArea, TextField } from "../Form/Fields";
import Rating from "../Form/Fields/Rating";
import generateFeedbackValidationSchema from "./feedback-schema";
import useReservationService from "../../hooks/use-reservation-service";
import clxs from "./feedback.module.css";
// import GoogleFeedbackModal from "./GoogleFeedbackModal";
import FeedbackSubmitModal from "./FeedbackSubmitModal";
import LOHONO_LOGO from "../../assets/logo/lohono-logo.svg";
// import { useQueryParamValue } from "../../hooks/use-query-param";

function Feedback() {
  const feedbackSlug = usePathParams(PATH, "feedback_slug"),
    fingerprint = useFingerprint(),
    { error, feedbackArgs } = useFeedbackArgs(feedbackSlug),
    { service } = useReservationService(),
    enqueueSnackbar = useSnackbar(),
    // showThankYouModal = useShowModal(THANK_YOU_MODAL_ID),
    showFeedbackModal = useShowModal(FEEDBACK_SUBMIT_MODAL_ID),
    // modalId = useQueryParamValue("string", "modal_id"),
    showPropertySelection = useMemo(
      () => {
        if (!feedbackArgs) {
          return false;
        }

        const { properties } = feedbackArgs;

        return properties.length > 0;
      },
      [feedbackArgs],
    ),
    defaultValue = useMemo(
      () => {
        if (!feedbackArgs) {
          return {};
        }

        const { properties, guest_name, questionnaire } = feedbackArgs;

        const firstProperty = properties.length ? properties[0].value : "";

        const questionnaireDefaultValues = questionnaire.reduce(
          (compiled, each) => {
            const { id, question_type } = each;

            if (question_type === "free_text") {
              return {
                ...compiled,
                [id]: "",
              };
            }

            if (question_type === "rating") {
              return {
                ...compiled,
                [id]: { rating: -1, description: "" },
              };
            }

            if (question_type === "rating_large") {
              return {
                ...compiled,
                [id]: { rating: -1, description: "" },
              };
            }

            if (question_type === "text_area") {
              return {
                ...compiled,
                [id]: "",
              };
            }

            return compiled;
          },
          {} as any,
        );

        return {
          property: firstProperty,
          guest_name: guest_name,
          ...questionnaireDefaultValues,
        };
      },
      [feedbackArgs],
    ),
    validationSchema = useMemo(
      () => {
        if (!feedbackArgs) {
          return undefined;
        }

        return generateFeedbackValidationSchema(feedbackArgs);
      },
      [feedbackArgs],
    ),
    handleSubmit = useCallback(
      async (values: any) => {
        const {
            guest_name,
            property,
            ...rest
          } = values,
          questionnaire = Object.entries(rest)
            .map(
              ([key, meta]: [string, any]) => {
                const questionId = Number.parseInt(key),
                  isRatingType = typeof meta === "object",
                  value = isRatingType ? (meta.rating || "NA") : undefined,
                  description = isRatingType ? meta.description : (meta ?? "");

                const formatted = {
                  id: questionId,
                  value: value,
                  description: description,
                };

                return formatted;
              },
            );

        const { error } = await service.saveFeedback(
          feedbackSlug,
          property,
          guest_name,
          questionnaire,
          { fingerprint: fingerprint },
        );

        if (error) {
          enqueueSnackbar(error.message, "error");

          return;
        }

        const overallRatingId = getOverallRatingId(feedbackArgs?.questionnaire ?? []);

        const overallRatingValue = questionnaire.find(({ id }) => id === overallRatingId)?.value;

        const tierName = feedbackArgs?.tier_name;
        const userId = feedbackArgs?.user_id;

        const modalPropsObj = {
          userId: userId,
          name: guest_name,
          userTier: tierName,
          property: property,
        }

        /** Use case 2, if rating is greater then or equal to 4
            Show Feedback modal popup to add feedback to google and trip advisor.
         */
        if(overallRatingValue >= 4) {
          await showFeedbackModal(modalPropsObj);
          return;
        }
        
        /**Use case 1, if rating is less then 4. 
          Show thank you message
        */    
        enqueueSnackbar(
          "Your feedback has been successfully captured, Thank you!",
          "success",
        );
      },
      [fingerprint, feedbackArgs],
    );

  if (error) {
    return (
      <Alert severity="error">
        {error}
      </Alert>
    );
  }

  if (!feedbackArgs) {
    return null;
  }

  return (
    <>
      <div className={clxs.container}>
        <Form
          id={FEEDBACK_FORM_ID}
          className={clxs.form}
          defaultValue={defaultValue}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <img
            alt="loho"
            className={clxs.logo}
            src={LOHONO_LOGO}
          />
          <div className={clxs.description}>
            {feedbackArgs.description ?? DESCRIPTION}
          </div>
          {showPropertySelection && (
            <Select
              form={FEEDBACK_FORM_ID}
              name="property"
              options={feedbackArgs.properties}
              label="Property *"
              className={clxs.question}
            />
          )}
          <TextField
            form={FEEDBACK_FORM_ID}
            name="guest_name"
            label="Guest name *"
            className={clxs.question}
          />
          {feedbackArgs.questionnaire.map(
            (each, key) => {
              const { id, question, question_type, required } = each;

              const label = decorateLabel(question, required);

              if (question_type === "free_text") {
                return (
                  <TextField
                    form={FEEDBACK_FORM_ID}
                    name={id.toString()}
                    label={label}
                    key={key}
                    className={clxs.question}
                  />
                );
              }

              if (question_type === "rating") {
                const freeTextLabel = `How can we improve ${question}?`;

                const naOption = buildNoneOption(id);

                return (
                  <Rating
                    form={FEEDBACK_FORM_ID}
                    name={id.toString()}
                    label={label}
                    key={key}
                    className={clxs.question}
                    optionsClassName={clxs.ratingContainer}
                    optionClassName={clxs.rating}
                    freeTextLabel={freeTextLabel}
                    freeTextOn={3}
                    noneOption={naOption}
                  />
                );
              }

              if (question_type === "rating_large") {
                const freeTextLabel = "How can we improve your experience?";

                return (
                  <Rating
                    form={FEEDBACK_FORM_ID}
                    name={id.toString()}
                    label={label}
                    key={key}
                    className={clxs.question}
                    optionsClassName={clxs.ratingLargeContainer}
                    optionClassName={clxs.ratingLarge}
                    freeTextLabel={freeTextLabel}
                    freeTextOn={8}
                    max={10}
                    scaleLegend={(
                      <div className={clxs.ratingLargeLegend}>
                        <span>Unlikely</span>
                        <span>Very Likely</span>
                      </div>
                    )}
                  />
                );
              }

              if (question_type === "text_area") {
                return (
                  <TextArea
                    key={key}
                    form={FEEDBACK_FORM_ID}
                    name={id.toString()}
                    label={label}
                    className={clxs.question}
                    rows={4}
                  />
                );
              }

              return null;
            },
          )}
        </Form>
        <Button
          className={clxs.submit}
          type="submit"
          form={FEEDBACK_FORM_ID}
        >
          Submit Feedback
        </Button>
      </div>
      {/* <Modal id={THANK_YOU_MODAL_ID}>
        <GoogleFeedbackModal />
      </Modal>  */}
      <Modal id={FEEDBACK_SUBMIT_MODAL_ID}>
        <FeedbackSubmitModal></FeedbackSubmitModal>
      </Modal> 
    </>
  );
}

export default Feedback;

function decorateLabel(label: string, required: boolean) {
  if (!required) {
    return `${label}?`;
  }

  return `${label}? *`;
}

function getOverallRatingId(questionnaire: QuestionnaireItem[]) {
  if (questionnaire.length < 3) {
    return -1;
  }

  /** Hot fixing the to find the questionnare from questions text with overall in it as requested by Product Manager since one more question was added in future the module should have a flag to identify the overall rating question. */
  const overallRatingQuestion = questionnaire.find((oQuestion) => {
    return oQuestion.question.toLowerCase().includes("overall");
  })

  return overallRatingQuestion ? overallRatingQuestion.id : -1;
}

const FEEDBACK_FORM_ID = "feedback";

const DESCRIPTION = "At Lohono Stays, we strive to provide you the absolute best service and your feedback helps us continuously learn and enhance your experience.";

// const THANK_YOU_MODAL_ID = "thankyou-modal";

const FEEDBACK_SUBMIT_MODAL_ID = "feedback-submit-modal";

const PATH = "/feedback/:feedback_slug";

function buildNoneOption(id: number) {

  if (id === 2) {
    return {
      value: 0,
      label: "Didn't Receive",
    }
  }

  if (id === 8) {
    return {
      value: 0,
      label: "Didn't Access",
    };
  }

  if (id === 5 || id === 6) {
    return {
      value: 0,
      label: "Not Availed",
    };
  }

  return undefined;
}
